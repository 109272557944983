/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import { Avatar, Button, Checkbox, Divider, Dropdown, DropdownItem, DropdownMenu, DropdownSection, DropdownTrigger, Input, Select, SelectItem, Slider, Textarea, Tooltip } from '@nextui-org/react';
import { IconArrowLeft, IconChevronDown, IconFileTypePdf, IconInfinity, IconLink, IconPlus, IconTrash, IconUnlink, IconX } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { useForm } from './use-form-create-product';
import { classNames, sanitizeToNumbers } from '../../../utils';

const IS_DEDUCTIBLE_BASED = {
  YES: 1,
  NO: 0,
};

const DOLLAR_SIGN = <span className="text-sm text-gray-400">$</span>;

const CreateProduct = () => {
  const nav = useNavigate();
  const { addDeductible, addDeductibleAge, addDeductiblePremiums, addPrice, addPriceProp, addRequiredDoc, addService, changeDeductible, changeForm, changePrice, changePriceProp, changeRequiredDoc, changeService, deductibles, form, getMarketingChannels, isLoading, marketingChannels, prices, removeDeductible, removeDeductibleAge, removeDeductiblePremiums, removePrice, removePriceProp, removeRequiredDoc, removeService, requiredDocs, selectedMarketingChannels, services, servicesList, submit, toggleMarketingChannel } = useForm();
  const [search, setSearch] = useState('');

  const onSubmit = async () => {
    const success = await submit();
    if (success) nav('/productos');
  }

  useEffect(() => {
    const details_file = document.getElementById('details-file');
    const dropzone = document.getElementById('dropzone');

    dropzone.addEventListener('dragover', (e) => {
      e.preventDefault();
      dropzone.classList.add('!border-primary');
    });

    dropzone.addEventListener('dragleave', (e) => {
      e.preventDefault();
      dropzone.classList.remove('!border-primary');
    });

    dropzone.addEventListener('drop', (e) => {
      e.preventDefault();
      dropzone.classList.remove('!border-primary');
      changeForm(e.dataTransfer.files[0], 'details_file');
    });

    details_file.addEventListener('change', e => {
      changeForm(e.target.files[0], 'details_file');
      e.target.value = "";
    });

    return () => {
      dropzone.removeEventListener('dragover', () => {});
      dropzone.removeEventListener('dragleave', () => {});
      dropzone.removeEventListener('drop', () => {});
      details_file.removeEventListener('change', () => {});
    }
  }, []);

  return (
    <div id="modal-create-product">
      <div className="flex gap-2 mb-4 font-semibold cursor-pointer" onClick={() => nav('/productos')}>
        <IconArrowLeft />
        <span>Volver</span>
      </div>

      <div className="items-start">
        <div className="w-full flex flex-col gap-y-4">
          <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-y-4">
            <label className="flex items-center gap-2 cursor-pointer" htmlFor="main-photo">
              <Avatar size="lg" radius="sm" name="Foto" src={form.photoUrl} />
              <div className="flex flex-col">
                <small className="font-semibold">Foto del producto</small>
                <small>Subir imagen JPG o PNG</small>
                <small>máximo 318px por 200px</small>
              </div>
              <input
                id="main-photo"
                type="file"
                accept="image/*"
                hidden
                onChange={e => changeForm(e.target.files[0], 'photo')}
              />
            </label>

            <label className="flex md:flex-row-reverse items-center gap-2 cursor-pointer" htmlFor="central-icon">
              <Avatar size="lg" radius="sm" name="Foto" src={form.centralIconUrl} />
              <div className="flex flex-col">
                <small className="font-semibold">Banner central (App)</small>
                <small>Subir imagen JPG o PNG</small>
              </div>
              <input
                id="central-icon"
                type="file"
                accept="image/*"
                hidden
                onChange={e => changeForm(e.target.files[0], 'central_icon')}
              />
            </label>
          </div>

          <Input
            label="Nombre"
            variant="bordered"
            classNames={{ inputWrapper: 'border-1 h-12' }}
            value={form.name}
            onValueChange={v => changeForm(v, 'name')}
          />

          <Textarea
            label="Descripción"
            variant="bordered"
            classNames={{ inputWrapper: 'border-1 h-12' }}
            value={form.description}
            onValueChange={v => changeForm(v, 'description')}
          />

          <div className="flex items-center justify-between gap-4 h-12">
            <div>
              <Input
                label="Cantidad de Cuotas"
                variant="bordered"
                type="number"
                step="1"
                classNames={{ inputWrapper: 'border-1 h-12' }}
                value={form.fees}
                onValueChange={v => changeForm(v, 'fees')}
              />
            </div>

            <Dropdown>
              <DropdownTrigger>
                <Button
                  endContent={<IconChevronDown size={16} />}
                  className="border-1 h-12 bg-white"
                >
                  Canal de comercialización
                </Button>
              </DropdownTrigger>
              <DropdownMenu
                aria-label="Multiple selection with search"
                variant="flat"
                selectionMode="multiple"
                selectedKeys={form.marketing_owners}
                onSelectionChange={v => {
                  changeForm(v, 'marketing_owners');
                  toggleMarketingChannel(Array.from(v.values()));
                }}
                classNames={{ base: 'max-w-80 max-h-80 overflow-auto' }}
              >
                <DropdownSection>
                  <DropdownItem
                    key="search"
                    textValue="search"
                    isReadOnly
                    classNames={{ base: 'p-0 m-0', selectedIcon: 'hidden' }}
                  >
                    <Input
                      placeholder="Buscar por nombre"
                      variant="bordered"
                      classNames={{
                        inputWrapper: 'border-1 h-10',
                      }}
                      value={search}
                      onValueChange={v => {
                        getMarketingChannels(v);
                        setSearch(v);
                      }}
                      isClearable
                      onClear={() => {
                        setSearch('');
                        getMarketingChannels('');
                      }}
                    />
                  </DropdownItem>
                </DropdownSection>
                <DropdownSection>
                  {marketingChannels.map((item) => (
                    <DropdownItem key={item.value} value={item.value} textValue={item.label}>
                      [{ String(item.marketing_type_text).substring(0, 4) }] { item.label }
                    </DropdownItem>
                  ))}
                </DropdownSection>
                {marketingChannels.length === 0 && (
                  <DropdownSection>
                    <DropdownItem key="empty" textValue="empty" isReadOnly>
                      <p className="text-center">No hay resultados</p>
                    </DropdownItem>
                  </DropdownSection>
                )}
              </DropdownMenu>
            </Dropdown>
          </div>

          <div>
            {selectedMarketingChannels.map((user, userIdx) => (
              <p
                key={userIdx}
                className="text-right text-sm text-primary font-semibold mb-1 cursor-pointer hover:text-red transition-all"
                onClick={() => {
                  const items = selectedMarketingChannels.filter(x => x.id !== user.id).map(x => x.id);
                  changeForm(items, 'marketing_owners');
                  toggleMarketingChannel(items);
                }}
                title="Click para eliminar"
              >
                [{ String(user.marketing_type_text).substring(0, 4) }] { user.name }
              </p>
            ))}
          </div>

          <Divider />

          <h1 className="font-semibold text-primary">Tipo de producto</h1>
          <div className="flex justify-between gap-4">
            <div className="flex justify-start gap-4">
              <TypeCheckbox
                label="Prima Única"
                onClick={() => changeForm(IS_DEDUCTIBLE_BASED.NO, 'is_deductible_based')}
                isSelected={!form.is_deductible_based}
              />
              <TypeCheckbox
                label="Deducible / Prima por Rango de Edad"
                onClick={() => changeForm(IS_DEDUCTIBLE_BASED.YES, 'is_deductible_based')}
                isSelected={!!form.is_deductible_based}
              />
            </div>

            <div>
              {!!form.is_deductible_based ? (
                <Tooltip content="Agregar deducible" classNames={{ content: 'text-primary font-medium' }}>
                  <Button color="primary" variant="flat" isIconOnly onClick={addDeductible}>
                    <IconPlus color="hsl(var(--sq-primary-700))" />
                  </Button>
                </Tooltip>
              ) : (
                <Tooltip content="Agregar precio" classNames={{ content: 'text-primary font-medium' }}>
                  <Button color="primary" variant="flat" isIconOnly onClick={addPrice}>
                    <IconPlus color="hsl(var(--sq-primary-700))" />
                  </Button>
                </Tooltip>
              )}
            </div>
          </div>

          <Divider />

          {!form.is_deductible_based && (
            <div className="prices">
              <div className="price font-semibold">
                <div />
                <span>Prima Mensual</span>
                <span>Prima Anual</span>
                <div className="w-10" />
              </div>
              {prices.map((item, priceIdx) => (
                <div key={priceIdx} className="[&:not(:last-child)]:border-b [&:not(:last-child)]:pb-4">
                  <div className="price">
                    <Input
                      label="Nombre"
                      variant="bordered"
                      className="input-1"
                      classNames={{ inputWrapper: 'border-1 h-12' }}
                      value={item.name}
                      onValueChange={v => changePrice(v, 'name', priceIdx)}
                    />

                    <Input
                      label={`Precio($) ${priceIdx + 1}`}
                      variant="bordered"
                      className="input-2"
                      classNames={{ inputWrapper: 'border-1 h-12' }}
                      value={item.price_monthly}
                      onValueChange={v => changePrice(v, 'price_monthly', priceIdx)}
                    />

                    <Input
                      label={`Precio($) ${priceIdx + 1}`}
                      variant="bordered"
                      className="input-3"
                      classNames={{ inputWrapper: 'border-1 h-12' }}
                      value={item.price_annual}
                      onValueChange={v => changePrice(v, 'price_annual', priceIdx)}
                    />

                    <div className="input-actions">
                      <Tooltip content="Eliminar precio">
                        <Button variant="flat" isIconOnly onClick={() => removePrice(priceIdx)}>
                          <IconX className="cursor-pointer" />
                        </Button>
                      </Tooltip>
                    </div>

                    <div className="flex gap-2">
                      <Checkbox
                        defaultValue={true}
                        isSelected={!item.insurability_age?.every(x => x === 0)}
                        onClick={() => {
                          const isEmpty = item.insurability_age?.every(x => x === 0);
                          const value = isEmpty ? [0,85] : [0,0];
                          changePrice(value, 'insurability_age', priceIdx);
                        }}
                      />
                      <Slider
                        label="Edad de asegurabilidad"
                        step={1}
                        minValue={0}
                        maxValue={100}
                        defaultValue={[0, 85]}
                        className="max-w-md"
                        size="sm"
                        value={item.insurability_age}
                        onChange={v => changePrice(v, 'insurability_age', priceIdx)}
                      />
                    </div>

                    <Input
                      label="Suma Asegurada"
                      type="number"
                      variant="bordered"
                      classNames={{ inputWrapper: 'border-1 h-12' }}
                      value={item.insured_sum}
                      onValueChange={v => changePrice(v, 'insured_sum', priceIdx)}
                    />

                    <Input
                      label="Deducible"
                      variant="bordered"
                      classNames={{ inputWrapper: 'border-1 h-12' }}
                      value={item.deductible}
                      onValueChange={v => changePrice(v, 'deductible', priceIdx)}
                    />
                  </div>

                  <div className="flex flex-1 items-center justify-between my-4">
                    <h1 className="flex-1 font-semibold">
                      Propiedades adicionales
                    </h1>

                    <div>
                      <Tooltip content="Agregar propiedad" classNames={{ content: 'text-primary font-medium' }}>
                        <Button color="primary" variant="flat" isIconOnly onClick={() => addPriceProp(priceIdx)}>
                          <IconPlus color="hsl(var(--sq-primary-700))" />
                        </Button>
                      </Tooltip>
                    </div>
                  </div>

                  <div className="flex flex-col gap-4">
                    {item.additional_props.map((prop, propIdx) => (
                      <div key={propIdx} className="flex flex-1 items-center justify-between gap-4">
                        <Input
                          label="Nombre de propiedad"
                          variant="bordered"
                          classNames={{ inputWrapper: 'border-1 h-12' }}
                          value={prop.name}
                          onValueChange={v => changePriceProp(v, 'name', propIdx, priceIdx)}
                        />
                        <Input
                          label="Monto (opcional)"
                          type="number"
                          variant="bordered"
                          classNames={{ inputWrapper: 'border-1 h-12' }}
                          value={prop.insured_sum}
                          onValueChange={v => changePriceProp(v, 'insured_sum', propIdx, priceIdx)}
                        />

                        <div className="input-actions">
                          <Tooltip content="Eliminar propiedad">
                            <Button variant="flat" isIconOnly onClick={() => removePriceProp(propIdx, priceIdx)}>
                              <IconX className="cursor-pointer" />
                            </Button>
                          </Tooltip>
                        </div>
                      </div>
                    ))}

                    {!item.additional_props.length && (
                      <p className="text-center text-gray-500 text-sm">No tiene propiedades adicionales</p>
                    )}
                  </div>
                </div>
              ))}

              {!prices.length && (
                <p className="text-center text-gray-500 text-sm">No ha establecido ningún precio</p>
              )}
            </div>
          )}

          {!!form.is_deductible_based && deductibles.map((item, dedIdx) => (
            <Deductible
              key={`ded-${dedIdx}`}
              index={dedIdx}
              item={item}
              onAddPremiums={addDeductiblePremiums}
              onAddAge={addDeductibleAge}
              onChange={changeDeductible}
              onRemove={removeDeductible}
              onRemovePremiums={removeDeductiblePremiums}
              onRemoveAge={removeDeductibleAge}
            />
          ))}

          {!!form.is_deductible_based && !deductibles.length && (
            <p className="text-center text-gray-500 text-sm">No ha establecido ningún deducible</p>
          )}

          <Divider />

          <Input
            label="Plazo de espera para activación"
            variant="bordered"
            classNames={{ inputWrapper: 'border-1 h-12' }}
            value={form.activation_period}
            onValueChange={v => changeForm(v, 'activation_period')}
          />

          <div className="flex flex-1 items-center justify-between">
            <h1 className="flex-1 font-semibold text-primary">
              ¿Aplica solo para el titular?
            </h1>

            <div>
              <Tooltip content="¿Aplica solo para el titular?" classNames={{ content: 'text-primary font-medium' }}>
                <Button
                  variant={form.is_only_for_owner ? 'solid':'bordered'}
                  color={form.is_only_for_owner ? 'primary':'default'}
                  isIconOnly
                  onClick={() => changeForm(!form.is_only_for_owner, 'is_only_for_owner')}
                >
                  { form.is_only_for_owner ? 'Sí':'No' }
                </Button>
              </Tooltip>
            </div>
          </div>

          <div className="flex flex-1 items-center justify-between">
            <h1 className="flex-1 font-semibold text-primary">
              ¿Requiere Declaración de Salud Resumida?
            </h1>

            <div>
              <Tooltip content="Requiere DSR?" classNames={{ content: 'text-primary font-medium' }}>
                <Button
                  variant={form.apply_dsr ? 'solid':'bordered'}
                  color={form.apply_dsr ? 'primary':'default'}
                  isIconOnly
                  onClick={() => changeForm(Number(!form.apply_dsr), 'apply_dsr')}
                >
                  { form.apply_dsr ? 'Sí':'No' }
                </Button>
              </Tooltip>
            </div>
          </div>

          <div className="flex flex-1 items-center justify-between">
            <div>
              <h1 className="flex-1 font-semibold text-primary">
                ¿Aplica condiciones especiales para APS?
              </h1>
              <p className="text-sm text-gray-500">Condiciones especiales como: Solicitar al usuario Informe Médico, Cédula de Identidad y Récipe al pedir un servicio</p>
            </div>

            <div>
              <Tooltip content="¿Aplica solo para el titular?" classNames={{ content: 'text-primary font-medium' }}>
                <Button
                  variant={form.is_aps ? 'solid':'bordered'}
                  color={form.is_aps ? 'primary':'default'}
                  isIconOnly
                  onClick={() => changeForm(!form.is_aps, 'is_aps')}
                >
                  { form.is_aps ? 'Sí':'No' }
                </Button>
              </Tooltip>
            </div>
          </div>

          <div className="flex flex-1 items-center justify-between">
            <div>
              <h1 className="flex-1 font-semibold text-primary">
                ¿Aplica condiciones especiales para RCV?
              </h1>
              <p className="text-sm text-gray-500">Condiciones especiales como: Permitir al usuario adquirir múltiples pólizas</p>
            </div>

            <div>
              <Tooltip content="¿Aplica solo para el titular?" classNames={{ content: 'text-primary font-medium' }}>
                <Button
                  variant={form.is_rcv ? 'solid':'bordered'}
                  color={form.is_rcv ? 'primary':'default'}
                  isIconOnly
                  onClick={() => changeForm(!form.is_rcv, 'is_rcv')}
                >
                  { form.is_rcv ? 'Sí':'No' }
                </Button>
              </Tooltip>
            </div>
          </div>

          <div className="flex flex-1 items-center justify-between">
            <div>
              <h1 className="flex-1 font-semibold text-primary">
                ¿Aplica llamada telefónica?
              </h1>
              <p className="text-sm text-gray-500">Al solicitar un servicio muestra las instrucciones de la llamada</p>
            </div>

            <div>
              <Tooltip content="¿Aplica llamada telefónica?" classNames={{ content: 'text-primary font-medium' }}>
                <Button
                  variant={form.apply_call ? 'solid':'bordered'}
                  color={form.apply_call ? 'primary':'default'}
                  isIconOnly
                  onClick={() => changeForm(!form.apply_call, 'apply_call')}
                >
                  { form.apply_call ? 'Sí':'No' }
                </Button>
              </Tooltip>
            </div>
          </div>

          {form.apply_call && (
            <Textarea
              classNames={{ inputWrapper: 'border-1 h-12' }}
              label="Instrucciones"
              variant="bordered"
              value={form.instructions}
              onValueChange={v => changeForm(v, 'instructions')}
            />
          )}

          <Divider className="mt-2" />

          <div className="flex flex-1 items-center justify-between">
            <h1 className="flex-1 font-semibold text-primary">
              ¿Qué ofrece? (Servicios que ofrece)
            </h1>

            <div>
              <Tooltip content="Agregar servicio" classNames={{ content: 'text-primary font-medium' }}>
                <Button color="primary" variant="flat" isIconOnly onClick={addService}>
                  <IconPlus color="hsl(var(--sq-primary-700))" />
                </Button>
              </Tooltip>
            </div>
          </div>

          <div className="services">
            {!!services.length && (
              <div className="header">
                <div>
                  <span className="font-medium">Icono</span>
                  <span className="text-xs font-normal leading-[14px]"> 800px * 800px</span>
                </div>
              </div>
            )}

            {services.map((item, index) => (
              <div className="service" key={index}>
                <label className="cursor-pointer" htmlFor={`service-photo-${index}`}>
                  <div>
                    <Avatar size="lg" radius="sm" name="Foto" src={item.photoUrl} />
                  </div>
                  <input
                    id={`service-photo-${index}`}
                    type="file"
                    hidden
                    accept="image/*"
                    onChange={e => changeService(e.target.files[0], 'photo', index)}
                  />
                </label>

                <Select
                  label="Servicio / Cobertura"
                  variant="bordered"
                  className="input-1"
                  classNames={{ trigger: 'border-1 h-12' }}
                  disallowEmptySelection={true}
                  selectedKeys={!!servicesList.length ? [item.service_id] : []}
                  onSelectionChange={v => changeService(v.currentKey, 'service_id', index)}
                >
                  {servicesList.map(o => (
                    <SelectItem key={o.value} value={o.value}>{ o.label }</SelectItem>
                  ))}
                </Select>

                <Input
                  label="Cantidad de usos"
                  variant="bordered"
                  className="input-2"
                  classNames={{ inputWrapper: 'border-1 h-12' }}
                  value={item.uses_quantity}
                  onValueChange={v => changeService(v, 'uses_quantity', index)}
                  isDisabled={item.is_unlimited}
                />

                <div className="input-actions">
                  <div>
                    <Tooltip content="Es ilimitado">
                      <Button
                        variant={item.is_unlimited ? 'solid':'bordered'}
                        color={item.is_unlimited ? 'primary':'default'}
                        isIconOnly
                        onClick={() => changeService(!item.is_unlimited, 'is_unlimited', index)}
                      >
                        <IconInfinity />
                      </Button>
                    </Tooltip>
                  </div>

                  <div>
                    <Tooltip content="Eliminar servicio">
                      <Button variant="flat" isIconOnly onClick={() => removeService(index)}>
                        <IconX />
                      </Button>
                    </Tooltip>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <Divider className="mt-2" />

          <div className="flex flex-1 items-center justify-between">
            <div>
              <h1 className="flex-1 font-semibold text-primary">
                Documentos requeridos (Opcional)
              </h1>
              <small>Aparecerán en el detalle del producto (App)</small>
            </div>

            <div>
              <Tooltip content="Agregar documento" classNames={{ content: 'text-primary font-medium' }}>
                <Button color="primary" variant="flat" isIconOnly onClick={addRequiredDoc}>
                  <IconPlus color="hsl(var(--sq-primary-700))" />
                </Button>
              </Tooltip>
            </div>
          </div>

          <div className="required-documents">
            {requiredDocs.map((item, index) => (
              <div className="document" key={`doc-${index}`}>
                <Input
                  label="Nombre del documento"
                  variant="bordered"
                  className="input-1"
                  classNames={{ inputWrapper: 'border-1 h-12' }}
                  value={item.name}
                  onValueChange={v => changeRequiredDoc(v, 'name', index)}
                />

                <div className="input-actions flex gap-4 items-center">
                  <Tooltip content="¿Es opcional?">
                    <Button
                      variant={item.is_optional ? 'solid':'bordered'}
                      color={item.is_optional ? 'primary':'default'}
                      isIconOnly
                      onClick={() => changeRequiredDoc(Number(!item.is_optional), 'is_optional', index)}
                    >
                      { item.is_optional ? 'Sí':'No' }
                    </Button>
                  </Tooltip>
                  <Tooltip content="Eliminar documento">
                    <Button variant="flat" isIconOnly onClick={() => removeRequiredDoc(index)}>
                      <IconX className="cursor-pointer" />
                    </Button>
                  </Tooltip>
                </div>
              </div>
            ))}
          </div>

          <Divider className="mt-2" />

          <div id="dropzone" className="dropzone">
            <div className="text-center">
              <IconFileTypePdf className="mx-auto h-12 w-12" />
              <h3 className="mt-2 text-sm font-medium text-gray-900">
                <input id="details-file" accept="application/pdf" type="file" hidden value="" onChange={() => {}} />

                {!!form?.details_file ? (
                  <>
                    <IconTrash
                      className="cursor-pointer absolute top-2 right-2"
                      onClick={() => changeForm('','details_file')}
                    />
                    <p className="text-center text-sm font-medium text-gray-900">
                      { form?.details_file?.name || 'Documento.pdf' }
                    </p>
                  </>
                ) : (
                  <label className="relative" htmlFor="details-file">
                    <span>Suelta aquí</span>
                    <span className="text-primary cursor-pointer"> o busca </span>
                    <span>el archivo</span>
                  </label>
                )}
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-8 mb-4 flex justify-end">
        <Button
          isLoading={isLoading}
          disabled={isLoading}
          color="primary"
          onPress={onSubmit}
        >
          Guardar
        </Button>
      </div>
    </div>
  )
}

const TypeCheckbox = ({ label, onClick, isSelected }) => (
  <div
    className={classNames('flex p-4 bg-white cursor-pointer rounded-lg border-2', isSelected ? 'border-primary' : '')}
    onClick={onClick}
  >
    <h1 className={classNames(isSelected ? 'text-primary' : '')}>{ label }</h1>
  </div>
);

const Deductible = ({ item, index, onAddAge, onAddPremiums, onChange, onRemove, onRemovePremiums, onRemoveAge }) => {
  return useMemo(() => (
    <div className="deducible">
      <div className="flex justify-between mb-4">
        <div>
          <Input
            label="Deducible"
            variant="bordered"
            classNames={{ inputWrapper: 'border-1 h-12' }}
            value={item.name}
            onValueChange={v => onChange(v, 'name', null, null, index)}
          />
        </div>

        <div className="flex flex-1 justify-end">
          <Tooltip content="Eliminar deducible" classNames={{ content: 'text-danger font-medium' }}>
            <Button color="danger" variant="flat" isIconOnly onClick={() => onRemove(index)}>
              <IconTrash />
            </Button>
          </Tooltip>
        </div>
      </div>

      <div className="flex gap-2">
        {/* Columna de Edad */}
        <div className="flex flex-col">
          <div className="h-16 mb-2" />

          <h1 className="font-semibold text-primary">Edad</h1>
          <div className="flex flex-col gap-2">
            {item.insurability_ages.map((row, rowIdx) => (
              <div key={`age-${rowIdx}`} className="flex w-24 justify-center items-center relative group">
                <Input
                  type="numeric"
                  maxLength={3}
                  variant="bordered"
                  classNames={{
                    inputWrapper: 'border-1 h-12 rounded-r-none border-r-0 px-1',
                    input: 'text-center',
                  }}
                  value={row.minValue}
                  onValueChange={v => onChange(sanitizeToNumbers(v), 'minValue', null, row.id, index)}
                />
                <Input
                  type="numeric"
                  maxLength={3}
                  variant="bordered"
                  classNames={{
                    inputWrapper: 'border-1 h-12 rounded-l-none border-l-0 px-1',
                    input: 'text-center',
                  }}
                  value={row.maxValue}
                  onValueChange={v => onChange(sanitizeToNumbers(v), 'maxValue', null, row.id, index)}
                />

                <div className="absolute -top-2 invisible group-hover:visible bg-[#DCE8FF] rounded-full">
                  <Tooltip
                    content="Agrega edad antes"
                    classNames={{ content: 'text-primary font-medium' }}
                    delay={1000}
                    closeDelay={0}
                    disableAnimation
                  >
                    <IconPlus
                      size={18}
                      className="cursor-pointer"
                      color="hsl(var(--sq-primary-700))"
                      onClick={() => onAddAge(index, rowIdx, false)}
                    />
                  </Tooltip>
                </div>

                <div className="absolute -bottom-2 invisible group-hover:visible bg-[#DCE8FF] rounded-full">
                  <Tooltip
                    content="Agrega edad después"
                    classNames={{ content: 'text-primary font-medium' }}
                    delay={1000}
                    closeDelay={0}
                    disableAnimation
                  >
                    <IconPlus
                      size={18}
                      className="cursor-pointer"
                      color="hsl(var(--sq-primary-700))"
                      onClick={() => onAddAge(index, rowIdx, true)}
                    />
                  </Tooltip>
                </div>

                <Divider className="absolute w-2" />
              </div>
            ))}
          </div>
        </div>

        {/* Columnas de Suma Asegurada */}
        <div className="flex gap-2 flex-1 overflow-x-auto scrollbar-x pb-4">
          {item.insured_sums.map((col, colIdx) => (
            <DeductiblePremiums
              key={`sum-${colIdx}`}
              dedIdx={index}
              col={col}
              deductible={item}
              onChange={onChange}
              onRemove={onRemovePremiums}
            />
          ))}
        </div>

        {/* Columna de Acciones */}
        <div className="flex flex-col">
          <div className="h-16 mb-2">
            <Tooltip content="Agregar columna" classNames={{ content: 'text-primary font-medium' }}>
              <Button color="primary" variant="flat" isIconOnly onClick={() => onAddPremiums(index)}>
                <IconPlus color="hsl(var(--sq-primary-700))" />
              </Button>
            </Tooltip>
          </div>

          <div className="flex flex-col gap-2 mt-6">
            {item.insurability_ages.map((row, ageIdx) => (
              <div key={`action-${ageIdx}`} className="h-12 flex items-center">
                <Tooltip content="Eliminar edad" classNames={{ content: 'text-danger font-medium' }}>
                  <Button variant="flat" isIconOnly onClick={() => onRemoveAge(index, row.id)}>
                    <IconX className="cursor-pointer" />
                  </Button>
                </Tooltip>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  ), [item]);
};

const DeductiblePremiums = ({ col, dedIdx, deductible, onChange, onRemove }) => {
  return (
    <div className="group flex flex-col min-w-60 max-w-60 gap-4">
      <div className="px-2">
        <Input
          type="number"
          label="Suma asegurada"
          variant="bordered"
          startContent={DOLLAR_SIGN}
          classNames={{ inputWrapper: 'border-1 h-12' }}
          value={col.insured_sum}
          onValueChange={v => onChange(v, 'insured_sum', col.id, null, dedIdx)}
        />
      </div>

      <div className="bg-[#DCE8FF] p-2 rounded-xl relative">
        <div>
          <h1 className="flex-1 font-semibold text-primary">Prima</h1>
          <div className="absolute top-1 right-1 invisible group-hover:visible">
            <Tooltip content="Eliminar columna" classNames={{ content: 'text-danger font-medium' }}>
              <IconX
                size={18}
                className="cursor-pointer"
                onClick={() => onRemove(dedIdx, col.id)}
              />
            </Tooltip>
          </div>
        </div>

        <div className="flex flex-col gap-2">
          {deductible.insurability_ages.map((row, rowIdx) => {
            const premium = deductible.premiums.find(x => x.col_id === col.id && x.row_id === row.id);
            return (
              <div key={`premium-${rowIdx}`} className="flex gap-2 group/input-action relative">
                <Input
                  type="number"
                  label="Mensual"
                  variant="bordered"
                  classNames={{ inputWrapper: 'border-1 h-12 px-2' }}
                  step="0.01"
                  value={premium?.price_monthly || ''}
                  onValueChange={v => onChange(v, 'price_monthly', col.id, row.id, dedIdx)}
                  isDisabled={premium?.is_disabled}
                  startContent={DOLLAR_SIGN}
                />
                <Input
                  type="number"
                  label="Anual"
                  variant="bordered"
                  classNames={{ inputWrapper: 'border-1 h-12 px-2' }}
                  step="0.01"
                  value={premium?.price_annual || ''}
                  onValueChange={v => onChange(v, 'price_annual', col.id, row.id, dedIdx)}
                  isDisabled={premium?.is_disabled}
                  startContent={DOLLAR_SIGN}
                />

                <div className="absolute bottom-3.5 -right-2 invisible group-hover/input-action:visible">
                  <Tooltip
                    content={`${premium?.is_disabled ? 'Habilitar' : 'Deshabilitar'} prima`}
                    classNames={{ content: classNames('font-medium', premium?.is_disabled ? 'text-primary' : 'text-danger') }}
                    delay={1000}
                    closeDelay={0}
                    disableAnimation
                  >
                    {premium?.is_disabled ? (
                      <IconLink
                        size={18}
                        className="cursor-pointer"
                        onClick={() => onChange(false, 'is_disabled', col.id, row.id, dedIdx)}
                      />
                    ) : (
                      <IconUnlink
                        size={18}
                        className="cursor-pointer"
                        onClick={() => onChange(true, 'is_disabled', col.id, row.id, dedIdx)}
                      />
                    )}
                  </Tooltip>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  );
};


export default CreateProduct;