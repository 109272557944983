/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { Button, Input, Pagination, Select, SelectItem, Spinner, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow } from '@nextui-org/react';
import { IconSearch } from '@tabler/icons-react';
import toast from 'react-hot-toast';
import moment from 'moment';
import { Constants, formatAmount, fromStorage } from '../../../../utils';
import { MarketingService } from '../../../../services';

const TYPE = Constants.USER.MARKETING_TYPE;

const types = [
  { value: Constants.USER.MARKETING_TYPE.DISTRIBUTOR.toString(), label: 'Distribuidor' },
  { value: Constants.USER.MARKETING_TYPE.SELLER.toString(), label: 'Vendedor' },
  { value: Constants.USER.MARKETING_TYPE.STORE.toString(), label: 'Comercio' },
  { value: Constants.USER.MARKETING_TYPE.INTERMEDIARY.toString(), label: 'Intermediario' },
];

const getUser = (item, type) => {
  return item?.commissions?.find(x => x.user?.marketing_type === type)?.user;
}

const getCommissionTotal = (item, id) => {
  return item?.commissions?.filter(x => x.user_id === id)?.map(x => x.amount)?.reduce((a,b) => a + b, 0);
}

const MarketingCommissionsReport = () => {
  const { canResetFilter, data, filterBy, goToPage, isLoading, pagination, print, reload } = useFetchTable();

  return (
    <>
      {isLoading && (
        <div className="w-screen h-screen fixed inset-0 z-[70] flex justify-center items-center bg-white/30">
          <Spinner />
        </div>
      )}

      <Filters
        canResetFilter={canResetFilter}
        filterBy={filterBy}
        resetFilter={() => reload()}
      />

      <Table aria-label="Reporte de comisiones"
        topContent={
          <div className="flex flex-row justify-between items-center gap-4 sticky top-0 left-0">
            <h3 className="text-xl font-medium text-primary">Reporte de Comisiones</h3>

            <div className="flex gap-2">
              <Button variant="bordered" onClick={print}>
                Exportar
              </Button>
            </div>
          </div>
        }
        topContentPlacement="inside"
      >
        <TableHeader>
          <TableColumn>N° de Venta</TableColumn>
          <TableColumn>Fecha de Venta</TableColumn>
          <TableColumn>Monto de Venta $</TableColumn>
          <TableColumn>Distribuidor</TableColumn>
          <TableColumn><span className="text-nowrap">Comisión $</span></TableColumn>
          <TableColumn>Vendedor</TableColumn>
          <TableColumn><span className="text-nowrap">Comisión $</span></TableColumn>
          <TableColumn>Comercio</TableColumn>
          <TableColumn><span className="text-nowrap">Comisión $</span></TableColumn>
          <TableColumn>Intermediario</TableColumn>
          <TableColumn><span className="text-nowrap">Comisión $</span></TableColumn>
        </TableHeader>
        <TableBody items={data} emptyContent="No hay resultados">
          {(item) => {

            const dist = getUser(item, TYPE.DISTRIBUTOR);
            const distAmount = getCommissionTotal(item, dist?.id);
            const sell = getUser(item, TYPE.SELLER);
            const sellAmount = getCommissionTotal(item, sell?.id);
            const stor = getUser(item, TYPE.STORE);
            const storAmount = getCommissionTotal(item, stor?.id);
            const inte = getUser(item, TYPE.INTERMEDIARY);
            const inteAmount = getCommissionTotal(item, inte?.id);
            const amount = item?.commissions[0]?.purchase_product?.price * item?.commissions[0]?.payment?.fees_qty;

            return (
              <TableRow key={item.id}>
                <TableCell>{ String(item.id).padStart(4,'0') }</TableCell>
                <TableCell>{ moment(item.created_at).format('DD/MM/YYYY') }</TableCell>
                <TableCell>{ formatAmount(amount) }</TableCell>
                <TableCell>{ dist?.name ?? '--' }</TableCell>
                <TableCell>{ distAmount ? formatAmount(distAmount) : '--' }</TableCell>
                <TableCell>{ sell?.name ?? '--' }</TableCell>
                <TableCell>{ sellAmount ? formatAmount(sellAmount) : '--' }</TableCell>
                <TableCell>{ stor?.name ?? '--' }</TableCell>
                <TableCell>{ storAmount ? formatAmount(storAmount) : '--' }</TableCell>
                <TableCell>{ inte?.name ?? '--' }</TableCell>
                <TableCell>{ inteAmount ? formatAmount(inteAmount) : '--' }</TableCell>
              </TableRow>
            )
          }}
        </TableBody>
      </Table>

      <div className="flex w-full justify-center mt-4">
        <Pagination
          showControls
          variant="bordered"
          page={pagination.page}
          total={pagination.pages}
          onChange={goToPage}
        />
      </div>
    </>
  )
}

const Filters = ({ canResetFilter, filterBy, resetFilter }) => {
  const initialFilter = {
    marketing_type: new Set([]),
    search: '',
    since: '',
    until: '',
  };
  const [form, setForm] = useState(initialFilter);

  const onChange = (value, target) => {
    setForm(s => ({ ...s, [target]: value }));
    filterBy(value, target);
  }

  return (
    <section className="mb-4 grid sm:flex grid-cols-2 lg:flex-row lg:flex-wrap items-end gap-4">
      <Select
        label="Tipo de Canal"
        labelPlacement="outside"
        placeholder="Seleccionar"
        variant="bordered"
        className="sd:max-w-xs"
        classNames={{ base: 'w-full sm:max-w-[12rem]', trigger: 'border-1' }}
        selectedKeys={form.marketing_type}
        selectionMode="multiple"
        onSelectionChange={v => onChange(v, 'marketing_type')}
      >
        {types.map((item) => (
          <SelectItem key={item.value} value={item.value}>
            { item.label }
          </SelectItem>
        ))}
      </Select>
      <Input
        classNames={{
          base: 'w-full sm:max-w-[12rem]',
          inputWrapper: 'border-1 h-10',
        }}
        label="Buscar"
        labelPlacement="outside"
        placeholder="Nombre"
        startContent={<IconSearch />}
        variant="bordered"
        value={form.search}
        onValueChange={v => onChange(v, 'search')}
      />
      <Input
        type="date"
        classNames={{
          base: 'w-full sm:max-w-[10rem]',
          inputWrapper: 'border-1 h-10',
          input: `pr-0 text-${!!form.since ? '[]':'foreground-400'}`,
        }}
        label="Desde"
        labelPlacement="outside"
        placeholder=" "
        variant="bordered"
        value={form.since}
        onValueChange={v => onChange(v, 'since')}
      />
      <Input
        type="date"
        classNames={{
          base: 'w-full md:max-w-[10rem]',
          inputWrapper: 'border-1 h-10',
          input: `pr-0 text-${!!form.until ? '[]':'foreground-400'}`,
        }}
        label="Hasta"
        labelPlacement="outside"
        placeholder=" "
        variant="bordered"
        value={form.until}
        onValueChange={v => onChange(v, 'until')}
      />
      {canResetFilter && (
        <Button
          variant="light"
          className="text-primaryDark"
          onClick={() => {
            setForm(initialFilter);
            resetFilter();
          }}
        >
          Limpiar filtros
        </Button>
      )}
    </section>
  )
}

const useFetchTable = () => {
  const initialFilters = {
    page: 1,
    perPage: Constants.PER_PAGE,
    marketing_type: new Set([]),
    search: '',
    since: null,
    until: null,
  };

  const initialPagination = {
    page: 1,
    pages: 1,
    total: 0,
    perPage: Constants.PER_PAGE,
  };

  const [data, setData] = useState([]);
  const [canFetch, setCanFetch] = useState(true);
  const [filters, setFilters] = useState(initialFilters);
  const [pagination, setPagination] = useState(initialPagination);

  const debounceTime = 500;
  const debounce = useRef();

  const fetchData = async () => {
    if (!canFetch) return;
    setCanFetch(false);

    try {
      const _filters = Object.assign({}, filters);
      _filters.marketing_type = Array.from(_filters.marketing_type).join(',');

      const response = await MarketingService.reports.findAllCommissions(_filters);
      const { data, ...rest } = response;

      setData(data);
      setPagination(rest);
      setCanFetch(true);

    } catch (error) {
      setData([]);
      onError(String(error));
    }
  }

  const print = async () => {
    setCanFetch(false);
    try {
      const _filters = Object.assign({}, filters);
      _filters.marketing_type = Array.from(_filters.marketing_type).join(',');

      const res = await MarketingService.reports.downloadAllCommissions(_filters);
      const el = document.createElement('a');
      el.href = fromStorage(res.url);
      el.download = 'Reporte de Comisiones';
      el.target = '_blank';
      el.click();
      el.remove();

    } catch (error) {
      onError(String(error));
    }
    setCanFetch(true);
  }

  const getCurrentPagination = () => {
    // Truco para obtener el estado actualizado (pagination es mantenida con el estado actual por el componente Pagination)
    let pag;
    setPagination(s => {
      pag = s;
      return s;
    });
    return pag;
  }

  const canResetFilter = () => {
    const { page, perPage, ...initial } = initialFilters;
    const { page: _, perPage: __, ...current } = filters;
    const initFilter = JSON.stringify(initial);
    const currFilter = JSON.stringify(current);
    return initFilter !== currFilter;
  }

  const onError = (msg) => toast.error(msg);

  const reload = (inSamePage = false) => {
    setCanFetch(true);
    if (!inSamePage) setFilters(initialFilters);
    else fetchData();
  }

  const goToPage = (page) => {
    const pagination = getCurrentPagination();
    if (page >= 1 && page <= pagination.pages && page !== pagination.page) {
      setCanFetch(true);
      setFilters(s => ({ ...s, page }));
    }
  }

  const changePerPage = (perPage) => {
    setCanFetch(true);
    setFilters({ ...filters, perPage });
  }

  const filterBy = (value, target) => {
    if (debounce.current) clearTimeout(debounce.current);
    debounce.current = setTimeout(() => {
      setCanFetch(true);
      setFilters({ ...filters, page: 1, [target]: value });
    }, debounceTime);
  }

  useEffect(() => {
    fetchData();
  }, [filters]);

  return {
    canResetFilter: canResetFilter(),
    changePerPage,
    data,
    filterBy,
    filters,
    goToPage,
    isLoading: !canFetch,
    pagination,
    print,
    reload,
  }
}

export default MarketingCommissionsReport;