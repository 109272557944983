/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Button, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Pagination, Select, SelectItem, Spinner, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow, Tooltip } from '@nextui-org/react';
import { IconBriefcase, IconEdit, IconEye, IconList, IconPlus, IconSearch, IconTrash } from '@tabler/icons-react';
import { Link, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import moment from 'moment';
import { Constants, fromPhotos, fromStorage, getReferralURL } from '../../../../utils';
import { MarketingService } from '../../../../services';
import { QRImage } from '../../../../components';
import { IconQR } from '../../../../assets/icons';
import { Logo } from '../../../../assets/images';
import ModalViewNet from './model-view-net';

const TYPE = Constants.USER.MARKETING_TYPE;

const types = [
  { value: TYPE.DISTRIBUTOR.toString(), label: 'Distribuidor' },
  { value: TYPE.SELLER.toString(), label: 'Vendedor' },
  { value: TYPE.STORE.toString(), label: 'Comercio' },
  { value: TYPE.INTERMEDIARY.toString(), label: 'Intermediario' },
];

const MODAL_ACTION = {
  NONE: 0,
  CREATE: 1,
  VIEW: 2,
  VIEW_NET: 3,
  VIEW_QR: 4,
  DELETE: 5,
}

const MarketingUsers = () => {
  const [selectedItem, setSelectedItem] = useState();
  const [modalAction, setModalAction] = useState(MODAL_ACTION.NONE);
  const { canResetFilter, data, deleteItem, filterBy, goToPage, isLoading, pagination, print, reload } = useFetchTable();

  const onSelectItem = (item, action) => {
    setSelectedItem(item);
    setModalAction(action);
  }

  const closeModal = (reloading = false) => {
    setSelectedItem(null);
    setModalAction(MODAL_ACTION.NONE);
    if (reloading) reload();
  }

  return (
    <>
      {isLoading && (
        <div className="w-screen h-screen fixed inset-0 z-[70] flex justify-center items-center bg-white/30">
          <Spinner />
        </div>
      )}

      <Modal
        size="lg"
        isOpen={modalAction === MODAL_ACTION.VIEW}
        onClose={() => closeModal()}
        backdrop="blur"
        scrollBehavior="outside"
      >
        <ModalContent>
          <ModalView user={selectedItem} />
        </ModalContent>
      </Modal>

      <Modal
        size="2xl"
        isOpen={modalAction === MODAL_ACTION.VIEW_NET}
        onClose={() => closeModal()}
        backdrop="blur"
        scrollBehavior="outside"
      >
        <ModalContent>
          <ModalViewNet item={selectedItem} />
        </ModalContent>
      </Modal>

      <Modal
        size="sm"
        isOpen={modalAction === MODAL_ACTION.VIEW_QR}
        onClose={() => closeModal()}
        backdrop="blur"
        scrollBehavior="outside"
      >
        <ModalContent>
          <ModalViewQR user={selectedItem} />
        </ModalContent>
      </Modal>

      <Modal
        size="sm"
        isOpen={modalAction === MODAL_ACTION.DELETE}
        onClose={() => closeModal()}
        backdrop="blur"
        scrollBehavior="outside"
      >
        <ModalContent>
          {(onClose) => (
            <ModalDelete
              user={selectedItem}
              onClose={onClose}
              onDelete={()=> {
                deleteItem(selectedItem?.id);
                closeModal();
              }}
            />
          )}
        </ModalContent>
      </Modal>

      <Modal
        size="sm"
        isOpen={modalAction === MODAL_ACTION.CREATE}
        onClose={() => closeModal()}
        scrollBehavior="outside"
      >
        <ModalContent className="max-w-[20rem]">
          <ModalHeader className="text-primary">Nuevo</ModalHeader>
          <ModalBody className="mb-4">
            <Link to="/canales-comercializacion/intermediarios/nuevo/alterno">
              <Button
                color="primary"
                variant="bordered"
                className="pl-2 font-semibold text-base"
                startContent={<IconList className="absolute left-4" />}
                fullWidth
              >
                Canal Alterno
              </Button>
            </Link>

            <Link to="/canales-comercializacion/intermediarios/nuevo">
              <Button
                color="primary"
                className="pl-2 font-semibold text-base"
                startContent={<IconBriefcase className="absolute left-4" color="white" />}
                fullWidth
              >
                Intermediario
              </Button>
            </Link>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Filters
        canResetFilter={canResetFilter}
        filterBy={filterBy}
        resetFilter={() => reload()}
      />

      <Table aria-label="Usuarios registrados"
        topContent={
          <div className="flex flex-row justify-between items-center gap-4 sticky top-0 left-0">
            <h3 className="text-xl font-medium text-primary">Usuarios registrados</h3>

            <div className="flex gap-2">
              <Button
                color="primary"
                className="pl-2"
                startContent={<IconPlus color="white" />}
                onPress={() => setModalAction(MODAL_ACTION.CREATE)}
              >
                Nuevo
              </Button>

              <Button variant="bordered" onClick={print}>
                Exportar
              </Button>
            </div>
          </div>
        }
        topContentPlacement="inside"
      >
        <TableHeader>
          <TableColumn>Fecha de registro</TableColumn>
          <TableColumn>Código de usuario</TableColumn>
          <TableColumn>Tipo de canal</TableColumn>
          <TableColumn>Nombre de usuario</TableColumn>
          <TableColumn>Actividad económica</TableColumn>
          <TableColumn>N° de documento</TableColumn>
          <TableColumn>Correo</TableColumn>
          <TableColumn>Teléfono fijo</TableColumn>
          <TableColumn>Teléfono móvil</TableColumn>
          <TableColumn>Ubicación</TableColumn>
          <TableColumn align="end" />
        </TableHeader>
        <TableBody items={data} emptyContent="No hay resultados">
          {(user) => {
            const userId = btoa(btoa(String(user.id).padStart(5,'0')));
            return (
              <TableRow key={user.id}>
                <TableCell>{ moment(user.created_at).format('DD/MM/YYYY') }</TableCell>
                <TableCell>
                  <div className="flex items-center gap-2">
                    {!!user?.marketing_qr_file && (
                      <img
                        src={IconQR}
                        className="w-4 h-4 object-contain cursor-pointer"
                        onClick={() => onSelectItem(user, MODAL_ACTION.VIEW_QR)}
                        alt="Código QR"
                      />
                    )}
                    <span>{ user.intermediary_code }</span>
                  </div>
                </TableCell>
                <TableCell>{ user.marketing_type_text }</TableCell>
                <TableCell>
                  {user?.marketing_type === Constants.USER.MARKETING_TYPE.INTERMEDIARY ? (
                    user.fullName
                  ) : (
                    <span
                      className="cursor-pointer underline decoration-primary underline-offset-2"
                      onClick={() => onSelectItem(user, MODAL_ACTION.VIEW_NET)}
                    >
                      { user.fullName }
                    </span>
                  )}
                </TableCell>
                <TableCell>{ user.economic_activity }</TableCell>
                <TableCell>{ user.dni }</TableCell>
                <TableCell>{ user.email }</TableCell>
                <TableCell>{ user.phone_secondary }</TableCell>
                <TableCell>{ user.phone }</TableCell>
                <TableCell>{ user.address }</TableCell>
                <TableCell align="right">
                  <div className="relative flex justify-end items-center gap-2">
                    <Tooltip content="Ver">
                      <IconEye className="cursor-pointer" onClick={() => onSelectItem(user, MODAL_ACTION.VIEW)} />
                    </Tooltip>
                    <Tooltip content="Editar">
                      <Link to={`/canales-comercializacion/intermediarios/${userId}/editar`}>
                        <IconEdit onClick={() => onSelectItem(user, MODAL_ACTION.EDIT)} />
                      </Link>
                      </Tooltip>
                    <Tooltip color="danger" content="Eliminar">
                      <IconTrash className="cursor-pointer" onClick={() => onSelectItem(user, MODAL_ACTION.DELETE)} />
                    </Tooltip>
                  </div>
                </TableCell>
              </TableRow>
            )
          }}
        </TableBody>
      </Table>

      <div className="flex w-full justify-center mt-4">
        <Pagination
          showControls
          variant="bordered"
          page={pagination.page}
          total={pagination.pages}
          onChange={goToPage}
        />
      </div>
    </>
  );
}

const Filters = ({ canResetFilter, filterBy, resetFilter }) => {
  const initialFilter = {
    marketing_type: new Set([]),
    code: '',
    search: '',
    search_activity: '',
    since: '',
    until: '',
  };
  const [form, setForm] = useState(initialFilter);

  const onChange = (value, target) => {
    setForm(s => ({ ...s, [target]: value }));
    filterBy(value, target);
  }

  return (
    <section className="mb-4 grid sm:flex grid-cols-2 lg:flex-row items-end gap-4">
      <Select
        label="Tipo de Canal"
        labelPlacement="outside"
        placeholder="Seleccionar"
        variant="bordered"
        className="sd:max-w-xs"
        classNames={{ base: 'w-full sm:max-w-[12rem]', trigger: 'border-1' }}
        selectedKeys={form.marketing_type}
        selectionMode="multiple"
        onSelectionChange={v => onChange(v, 'marketing_type')}
      >
        {types.map((item) => (
          <SelectItem key={item.value} value={item.value}>
            { item.label }
          </SelectItem>
        ))}
      </Select>
      <Input
        classNames={{
          base: 'w-full sm:max-w-[12rem]',
          inputWrapper: 'border-1 h-10',
        }}
        label="Código de Usuario"
        labelPlacement="outside"
        placeholder=" "
        variant="bordered"
        value={form.code}
        onValueChange={v => onChange(v, 'code')}
      />
      <Input
        classNames={{
          base: 'w-full sm:max-w-[12rem]',
          inputWrapper: 'border-1 h-10',
        }}
        label="Buscar"
        labelPlacement="outside"
        placeholder="Nombre"
        startContent={<IconSearch />}
        variant="bordered"
        value={form.search}
        onValueChange={v => onChange(v, 'search')}
      />
      <Input
        classNames={{
          base: 'w-full sm:max-w-[15rem]',
          inputWrapper: 'border-1 h-10',
        }}
        label="Buscar"
        labelPlacement="outside"
        placeholder="Actividad económica (Ramo)"
        startContent={<IconSearch />}
        variant="bordered"
        value={form.search_activity}
        onValueChange={v => onChange(v, 'search_activity')}
      />
      <Input
        type="date"
        classNames={{
          base: 'w-full sm:max-w-[10rem]',
          inputWrapper: 'border-1 h-10',
          input: `pr-0 text-${!!form.since ? '[]':'foreground-400'}`,
        }}
        label="Desde"
        labelPlacement="outside"
        placeholder=" "
        variant="bordered"
        value={form.since}
        onValueChange={v => onChange(v, 'since')}
      />
      <Input
        type="date"
        classNames={{
          base: 'w-full md:max-w-[10rem]',
          inputWrapper: 'border-1 h-10',
          input: `pr-0 text-${!!form.until ? '[]':'foreground-400'}`,
        }}
        label="Hasta"
        labelPlacement="outside"
        placeholder=" "
        variant="bordered"
        value={form.until}
        onValueChange={v => onChange(v, 'until')}
      />
      {canResetFilter && (
        <Button
          variant="light"
          className="text-primaryDark"
          onClick={() => {
            setForm(initialFilter);
            resetFilter();
          }}
        >
          Limpiar filtros
        </Button>
      )}
    </section>
  )
}

const ModalView = ({ user }) => {
  const nav = useNavigate();

  const onEdit = () => {
    const userId = btoa(btoa(String(user.id).padStart(5,'0')));
    nav(`/canales-comercializacion/intermediarios/${userId}/editar`);
  }

  const onPrint = () => {
    const printContent = document.getElementById('printable-content').innerHTML;
    const printArea = document.getElementById('print-area');
    printArea.innerHTML = printContent;
    window.print();
    printArea.innerHTML = '';
  }

  const getPercByLevel = (itemIdx) => {
    if (user.marketing_type === TYPE.DISTRIBUTOR)
      return user.products_commissions[itemIdx].commission_perc_distributor;
    if (user.marketing_type === TYPE.SELLER)
      return user.products_commissions[itemIdx].commission_perc_seller;
    if (user.marketing_type === TYPE.STORE)
      return user.products_commissions[itemIdx].commission_perc_store;
    return 0;
  }

  return (
    <>
      <ModalHeader className="flex flex-col gap-1 text-primary">{ user.fullName }</ModalHeader>
      <ModalBody>
        <div id="printable-content">
          <div className="[&>p>span]:font-semibold">
            <p><span>Nombre</span>: { user.fullName }</p>
            <p><span>N° de documento</span>: { user.dni }</p>
            <p><span>Teléfono fijo</span>: { user.phone_secondary }</p>
            <p><span>Teléfono móvil</span>: { user.phone }</p>
            <p><span>Correo electrónico</span>: { user.email }</p>
            <p><span>Actividad económica</span>: { user.economic_activity }</p>
            <p><span>Dirección</span>: { user.address }</p>
            <p><span>Estado</span>: { user?.state?.name ?? '--' }</p>
            <p><span>Municipio</span>: { user?.township?.name ?? '--' }</p>
            <p><span>Parroquia</span>: { user?.parish?.name ?? '--' }</p>
          </div>

          <p className="font-bold text-primary mt-4">Autorizado para</p>
          <p>Asignar código QR a comercios afiliados para venta de</p>
          {(user.can_assign_qr && !!user?.can_sell_all_products) && (
            <p>Todos los productos</p>
          )}
          {(user.can_assign_qr && !user?.can_sell_all_products) && (
            <>
              <p>Productos asignados para la venta</p>
              <div className="grid grid-cols-4 text-sm font-semibold">
                {user.products_commissions?.map((x, idx) => (
                  <Fragment key={idx}>
                    <span className="col-span-3">{ x?.product?.name }</span>
                    <span>
                      {user.marketing_type === TYPE.DISTRIBUTOR && (
                        <span>({ x?.commission_perc }%) </span>
                      )}
                      <span>{ getPercByLevel(idx) }%</span>
                    </span>
                  </Fragment>
                ))}
              </div>
            </>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          className="border-primary text-primary font-semibold"
          variant="bordered"
          onPress={onEdit}
        >
          Editar
        </Button>
        <Button className="font-semibold" onPress={onPrint} color="primary">
          <span>Imprimir</span>
        </Button>
      </ModalFooter>
    </>
  )
}

const ModalViewQR = ({ user }) => {
  const onCopy = (content) => {
    navigator.clipboard.writeText(content);
    toast.success('Código copiado al portapapeles');
  }

  const onPrint = () => {
    const printContent = document.getElementById('qr-image').innerHTML;
    const printArea = document.getElementById('print-area');
    printArea.innerHTML = printContent;
    window.print();
    printArea.innerHTML = '';
  }

  return (
    <>
      <ModalHeader className="flex flex-col gap-1">Códigos QR asignados</ModalHeader>
      <ModalBody>
        <div id="qr-image" className="mx-auto flex flex-col justify-between items-center">
          <img src={Logo} className="w-36 object-contain mx-auto hidden printable" alt="logo" />

          <div className="w-min mx-auto">
            <QRImage
              image={user?.marketing_qr_file ? fromPhotos(user?.marketing_qr_file) : undefined}
              name={user.fullName}
            />
          </div>

          <p className="mt-4 text-sm text-primary break-all unprintable">
            { getReferralURL(user.marketing_qr_id) }
          </p>
        </div>
      </ModalBody>
      <ModalFooter>
        {!!user?.marketing_qr_file && (
          <Button
            variant="solid"
            color="primary"
            onPress={() => onCopy(getReferralURL(user.marketing_qr_id))}
          >
            Copiar
          </Button>
        )}
        {!!user?.marketing_qr_file && (
          <Button variant="bordered" onPress={onPrint} className="border-primary text-primary font-semibold">
            Imprimir
          </Button>
        )}
      </ModalFooter>
    </>
  )
}

const ModalDelete = ({ user, onClose, onDelete }) => {
  return (
    <>
      <ModalHeader className="flex flex-col gap-1">Confirmación</ModalHeader>
      <ModalBody>
        <p className="text-center">¿Estás seguro de eliminar este usuario?</p>
        <p className="text-center font-medium">{ user.fullName }</p>
      </ModalBody>
      <ModalFooter className="justify-evenly">
        <Button variant="light" onPress={onClose}>Cancelar</Button>
        <Button color="danger" onPress={onDelete}>Aceptar</Button>
      </ModalFooter>
    </>
  )
}

const useFetchTable = () => {
  const initialFilters = {
    page: 1,
    perPage: Constants.PER_PAGE,
    marketing_type: new Set([]),
    code: '',
    search: '',
    search_activity: '',
    since: null,
    until: null,
  };

  const initialPagination = {
    page: 1,
    pages: 1,
    total: 0,
    perPage: Constants.PER_PAGE,
  };

  const [data, setData] = useState([]);
  const [canFetch, setCanFetch] = useState(true);
  const [filters, setFilters] = useState(initialFilters);
  const [pagination, setPagination] = useState(initialPagination);

  const debounceTime = 500;
  const debounce = useRef();

  const fetchData = async () => {
    if (!canFetch) return;
    setCanFetch(false);

    try {
      const _filters = Object.assign({}, filters);
      _filters.marketing_type = Array.from(_filters.marketing_type).join(',');

      const response = await MarketingService.user.findAll(_filters);
      const { data, ...rest } = response;

      setData(data);
      setPagination(rest);
      setCanFetch(true);

    } catch (error) {
      setData([]);
      onError(String(error));
    }
  }

  const deleteItem = async (id) => {
    if (!canFetch) return;
    setCanFetch(false);

    try {
      await MarketingService.user.destroy(id);
      toast.success('Usuario eliminado con éxito');
      fetchData();

    } catch (error) {
      onError(String(error));
    }
    setCanFetch(true);
  }

  const print = async () => {
    setCanFetch(false);
    try {
      const _filters = Object.assign({}, filters);
      _filters.marketing_type = Array.from(_filters.marketing_type).join(',');

      const res = await MarketingService.user.download(_filters);
      const el = document.createElement('a');
      el.href = fromStorage(res.url);
      el.download = 'Usuarios';
      el.target = '_blank';
      el.click();
      el.remove();

    } catch (error) {
      onError(String(error));
    }
    setCanFetch(true);
  }

  const getCurrentPagination = () => {
    // Truco para obtener el estado actualizado (pagination es mantenida con el estado actual por el componente Pagination)
    let pag;
    setPagination(s => {
      pag = s;
      return s;
    });
    return pag;
  }

  const canResetFilter = () => {
    const { page, perPage, ...initial } = initialFilters;
    const { page: _, perPage: __, ...current } = filters;
    const initFilter = JSON.stringify(initial);
    const currFilter = JSON.stringify(current);
    return initFilter !== currFilter;
  }

  const onError = (msg) => toast.error(msg);

  const reload = (inSamePage = false) => {
    setCanFetch(true);
    if (!inSamePage) setFilters(initialFilters);
    else fetchData();
  }

  const goToPage = (page) => {
    const pagination = getCurrentPagination();
    if (page >= 1 && page <= pagination.pages && page !== pagination.page) {
      setCanFetch(true);
      setFilters({ ...filters, page });
    }
  }

  const changePerPage = (perPage) => {
    setCanFetch(true);
    setFilters({ ...filters, perPage });
  }

  const filterBy = (value, target) => {
    if (debounce.current) clearTimeout(debounce.current);
    debounce.current = setTimeout(() => {
      setCanFetch(true);
      setFilters({ ...filters, page: 1, [target]: value });
    }, debounceTime);
  }

  useEffect(() => {
    fetchData();
  }, [filters]);

  return {
    canResetFilter: canResetFilter(),
    changePerPage,
    data,
    deleteItem,
    filterBy,
    filters,
    goToPage,
    isLoading: !canFetch,
    pagination,
    print,
    reload,
  }
}

export default MarketingUsers;
