/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { clone, Constants, copy, fromPhotos } from '../../../utils';
import { MarketingService, ProductsService, ServicesService } from '../../../services';

export const useForm = () => {
  const params = useParams();
  const isEditing = !!params.id;
  const { INITIAL_DEDUCTIBLE_AGES, INITIAL_DEDUCTIBLE_PREMIUM, INITIAL_DEDUCTIBLE_SUM, INITIAL_DEDUCTIBLE, INITIAL_FORM, INITIAL_PRICE_PROP, INITIAL_PRICE, INITIAL_REQ_DOC, INITIAL_SERVICES } = initialFormValues();
  const [canFetch, setCanFetch] = useState(true);
  const [form, setForm] = useState({ ...INITIAL_FORM });
  const [prices, setPrices] = useState([]);
  const [deductibles, setDeductibles] = useState([]);
  const [services, setServices] = useState([]);
  const [servicesList, setServicesList] = useState([]);
  const [requiredDocs, setRequiredDocs] = useState([]);
  const [marketingChannels, setMarketingChannels] = useState([]);
  const [selectedMarketingChannels, setSelectedMarketingChannels] = useState([]);
  const debounceTime = 500;
  const debounce = useRef();

  const onError = (msg) => {
    toast.error(msg);
    return false;
  }

  const fillForm = (product) => {
    setForm({
      id: product.id,
      photo: product.photo,
      photoUrl: product.photo ? fromPhotos(product.photo) : '',
      central_icon: product.central_icon,
      centralIconUrl: product.central_icon ? fromPhotos(product.central_icon) : '',
      details_file: product.details_file,
      name: product.name,
      description: product.description,
      fees: product.fees,
      activation_period: product.activation_period || '',
      instructions: product.instructions || '',
      apply_dsr: product.apply_dsr ?? true,
      apply_call: product.apply_call || false,
      is_only_for_owner: product.is_only_for_owner || false,
      is_aps: product.is_aps || false,
      is_rcv: product.is_rcv || false,
      is_deductible_based: product.is_deductible_based || false,
      marketing_owners: product?.marketing_owners?.map(x => String(x.user?.id)) || [],
    });

    setServices(product.services?.map(x => ({
      ...x,
      service_id: String(x.service_id),
      photoUrl: x.photo ? fromPhotos(x.photo) : '',
      uses_quantity: x.is_unlimited ? '' : x.uses_quantity,
    })) || []);

    setPrices(product.prices?.map(x => ({
      ...x,
      insurability_age: String(x.insurability_age).includes('-')
        ? String(x.insurability_age).split('-')
        : [0,0],
    })) || []);

    setDeductibles(product.deductibles?.map(d => {
      // Obtiene las columnas
      const rows = d.premiums?.filter(x => x.col_order === 1);
      // Obtiene las filas
      const cols = d.premiums?.filter(x => x.row_order === 1);

      return {
        ...d,
        insurability_ages: rows.map(p => ({
          id: p.row_order,
          minValue: String(p.insurability_age).includes('-')
            ? String(p.insurability_age).split('-')[0]
            : 0,
          maxValue: String(p.insurability_age).includes('-')
            ? String(p.insurability_age).split('-')[1]
            : 0,
        })),
        insured_sums: cols.map(p => ({
          id: p.col_order,
          insured_sum: p.insured_sum,
        })),
        premiums: d?.premiums?.map(p => ({
          ...p,
          col_id: p.col_order,
          row_id: p.row_order,
          is_disabled: Boolean(p.is_disabled),
          insurability_age: String(p.insurability_age).includes('-')
            ? String(p.insurability_age).split('-')
            : [0,0],
        })) || [],
      }
    }));

    setRequiredDocs(product.required_docs || []);
    setSelectedMarketingChannels(product.marketing_owners?.map(x => ({
      id: String(x.user?.id),
      name: x.user?.name,
      marketing_type_text: x.user?.marketing_type_text,
    })) || []);
    setCanFetch(true);
  }

  const getProduct = async () => {
    setCanFetch(false);

    try {
      const product = await ProductsService.admin.findOne(params.id);
      fillForm(product);

    } catch (error) {
      console.log(error)
      setCanFetch(true);
    }
  }

  const getServices = async () => {
    try {
      const response = await ServicesService.admin.findAllForSelect();
      setServicesList(response.map(x => ({ value: String(x.id), label: x.name })));

    } catch (error) {
      setServicesList([]);
      onError(String(error));
    }
  }

  const getMarketingChannels = async (search) => {
    if (debounce.current) clearTimeout(debounce.current);
    debounce.current = setTimeout(async () => {
      setCanFetch(false);
      try {
        const data = {
          marketing_type: [Constants.USER.MARKETING_TYPE.DISTRIBUTOR, Constants.USER.MARKETING_TYPE.INTERMEDIARY].join(','),
          search,
        };

        const response = await MarketingService.user.findAllForSelect(data);
        setMarketingChannels(response.map(x => ({
          value: String(x.id),
          label: x.name,
          marketing_type_text: x.marketing_type_text,
        })));

      } catch (error) {
        setMarketingChannels([]);
        onError(String(error));
      }
      setCanFetch(true);
    }, debounceTime);
  }

  const toggleMarketingChannel = (ids) => {
    if (!ids?.length) return setSelectedMarketingChannels([]);

    const users = [];
    ids.forEach(id => {
      const existingUser = selectedMarketingChannels.find(x => x.id === id);
      if (existingUser) {
        users.push(existingUser);

      } else {
        const user = marketingChannels.find(x => x.value === id);
        if (user) users.push({
          id: user.value,
          name: user.label,
          marketing_type_text: user.marketing_type_text,
        });
      }
    });

    setSelectedMarketingChannels(users);
  }

  const changeForm = (value, target) => {
    setForm(s => ({
      ...s,
      photoUrl: target === 'photo' && !!value ? URL.createObjectURL(value) : s.photoUrl,
      centralIconUrl: target === 'central_icon' && !!value ? URL.createObjectURL(value) : s.centralIconUrl,
      [target]: value,
    }));
  }

  const changeService = (value, target, index) => {
    setServices(s => {
      const services = s.map(x => Object.assign({}, x));
      services[index][target] = value;

      if (target === 'photo') {
        services[index].photoUrl = URL.createObjectURL(value);
      }

      if (target === 'is_unlimited' && value) {
        services[index].uses_quantity = '';
      }

      return services;
    });
  }

  const changePrice = (value, target, index) => {
    setPrices(s => {
      const prices = copy(s);
      prices[index][target] = value;
      return prices;
    });
  }

  const changePriceProp = (value, target, propIdx, priceIdx) => {
    setPrices(s => {
      const prices = copy(s);
      prices[priceIdx].additional_props[propIdx][target] = value;
      return prices;
    });
  }

  const changeDeductible = (value, target, colId, rowId, dedIdx) => {
    setDeductibles(deductibles => {
      const items = clone(deductibles);

      if (colId && rowId) {
        // Está editando una prima
        const itemIdx = items[dedIdx].premiums.findIndex(x => x.row_id === rowId && x.col_id === colId);
        if (itemIdx >= 0) {
          items[dedIdx].premiums[itemIdx][target] = value;
        }

      } else {
        if (colId && !rowId) {
          // Está editando la columna
          const baseIdx = items[dedIdx].insured_sums.findIndex(x => x.id === colId);
          if (baseIdx >= 0) {
            items[dedIdx].insured_sums[baseIdx][target] = value;
          }

          // Modifica los valores de todos los items de la columna
          items[dedIdx].premiums.forEach(premium => {
            if (premium.col_id === colId) premium[target] = value;
          });

        } else if (!colId && rowId) {
          // Está editando la fila
          const baseIdx = items[dedIdx].insurability_ages.findIndex(x => x.id === rowId);
          if (baseIdx >= 0) {
            items[dedIdx].insurability_ages[baseIdx][target] = value;
          }

          // Modifica los valores de todos los items de la fila
          items[dedIdx].premiums.forEach(premium => {
            if (premium.row_id === rowId) {
              if (target === 'minValue') {
                premium.insurability_age = [value, premium.insurability_age[1]];

              } else if (target === 'maxValue') {
                premium.insurability_age = [premium.insurability_age[0], value];

              } else {
                premium[target] = value;
              }
            }
          });

        } else {
          // Está editando la base
          items[dedIdx][target] = value;
        }
      }

      return items;
    });
  }

  const changeRequiredDoc = (value, target, index) => {
    setRequiredDocs(s => {
      const docs = copy(s);
      docs[index][target] = value;
      return docs;
    });
  }

  const addPrice = () => {
    setPrices(s => ([...s, { ...INITIAL_PRICE }]));
  }

  const addPriceProp = (priceIdx) => {
    setPrices(s => {
      const prices = copy(s);
      prices[priceIdx].additional_props?.push({ ...INITIAL_PRICE_PROP });
      return prices;
    });
  }

  const addDeductible = () => {
    const col_id = uuidv4();
    const newInsuredSum = { ...INITIAL_DEDUCTIBLE_SUM, id: col_id };
    const newPremiums = INITIAL_DEDUCTIBLE_AGES.map(item => ({
      ...INITIAL_DEDUCTIBLE_PREMIUM,
      col_id,
      row_id: item.id,
      insurability_age: [item.minValue, item.maxValue],
    }));

    setDeductibles(s => ([
      ...s,
      {
        ...INITIAL_DEDUCTIBLE,
        insurability_ages: [ ...INITIAL_DEDUCTIBLE_AGES ],
        insured_sums: [newInsuredSum],
        premiums: newPremiums,
      },
    ]));
  }

  const addDeductiblePremiums = (dedIdx) => {
    const col_id = uuidv4();
    const items = clone(deductibles);
    const newInsuredSum = { ...INITIAL_DEDUCTIBLE_SUM, id: col_id };
    const newPremiums = items[dedIdx].insurability_ages.map(row => ({
      ...INITIAL_DEDUCTIBLE_PREMIUM,
      col_id,
      row_id: row.id,
      insurability_age: [row.minValue, row.maxValue],
    }));

    items[dedIdx].insured_sums.push(newInsuredSum);
    items[dedIdx].premiums = items[dedIdx].premiums.concat(newPremiums);
    setDeductibles(items);
  }

  const addDeductibleAge = (dedIdx, rowIdx, after = true) => {
    const position = rowIdx + (after ? 1 : 0);
    const items = clone(deductibles);
    const newInsAge = {
      id: uuidv4(),
      minValue: '',
      maxValue: '',
    };

    // Agrega la nueva edad en cada columna
    const newAges = items[dedIdx].insured_sums.map(col => ({
      ...INITIAL_DEDUCTIBLE_PREMIUM,
      row_id: newInsAge.id,
      col_id: col.id,
      insured_sum: col.insured_sum,
      insurability_age: [newInsAge.minValue, newInsAge.maxValue],
    }));

    // Inserta la edad en la posición indicada por el usuario
    items[dedIdx].insurability_ages.splice(position, 0, newInsAge);
    items[dedIdx].premiums = items[dedIdx].premiums.concat(newAges);
    setDeductibles(items);
  }

  const addService = () => {
    setServices(s => ([...s, { ...INITIAL_SERVICES }]));
  }

  const addRequiredDoc = () => {
    setRequiredDocs(s => ([...s, { ...INITIAL_REQ_DOC }]));
  }

  const removeService = (index) => {
    const others = services.filter((_, idx) => idx !== index);
    setServices(others);
  }

  const removePrice = (index) => {
    const others = prices.filter((_, idx) => idx !== index);
    setPrices(others);
  }

  const removeDeductible = (index) => {
    const others = deductibles.filter((_, idx) => idx !== index);
    setDeductibles(others);
  }

  const removeDeductibleAge = (dedIdx, rowId) => {
    const items = clone(deductibles);
    items[dedIdx].insurability_ages = items[dedIdx].insurability_ages.filter(item => item.id !== rowId);
    items[dedIdx].premiums = items[dedIdx].premiums.filter(item => item.row_id !== rowId);
    setDeductibles(items);
  }

  const removeDeductiblePremiums = (dedIdx, colId) => {
    const items = clone(deductibles);
    items[dedIdx].insured_sums = items[dedIdx].insured_sums.filter(item => item.id !== colId);
    items[dedIdx].premiums = items[dedIdx].premiums.filter(item => item.col_id !== colId);
    setDeductibles(items);
  }

  const removePriceProp = (propIdx, priceIdx) => {
    setPrices(s => {
      const prices = copy(s);
      const priceProps = prices[priceIdx].additional_props;
      prices[priceIdx].additional_props = priceProps?.filter((_, idx) => idx !== propIdx) || [];
      return prices;
    });
  }

  const removeRequiredDoc = (index) => {
    const others = requiredDocs.filter((_, idx) => idx !== index);
    setRequiredDocs(others);
  }

  const isValidForm = () => {
    if (!form.name)
      return onError('El nombre es obligatorio');

    if (!form.description)
      return onError('La descripción es obligatoria');

    if (!form.activation_period)
      return onError('El plazo de espera de activación es obligatorio');

    if (!services.length)
      return onError('Debe agregar al menos un servicio');

    if (services.some(x => !x.service_id || !x.photoUrl || (!x.uses_quantity && !x.is_unlimited)))
      return onError('Debe completar todos los campos de los servicios');

    if (form.is_deductible_based) {
      if (!deductibles.length)
        return onError('Debe agregar al menos un deducible');

      if (deductibles.some(x => !x.premiums.length))
        return onError('Debe agregar al menos una prima por cada deducible');

      if (deductibles.some(x => x.premiums.some(x => !x.is_disabled && !x.insured_sum)))
        return onError('Debe agregar el precio de todas las primas habilitadas');

      if (deductibles.some(x => x.premiums.some(x => x.insurability_age.length !== 2)))
        return onError('Debe agregar al menos una prima');

      if (deductibles.some(x => x.premiums.some(x => x.insurability_age[0] >= x.insurability_age[1])))
        return onError('La edad de asegurabilidad mínima debe ser inferior a la máxima');

    } else {
      if (!prices.length)
        return onError('Debe agregar al menos un precio');

      if (!!prices?.length && prices.some(x => !x.name))
        return onError('Debe completar el nombre del precio');

      if (!!prices?.length && prices.some(x => !x.price_monthly && !x.price_annual))
        return onError('Debe definir al menos uno de los precios (prima mensual o prima anual) en el precio');

      if (!!prices?.length && prices.some(x => x.additional_props.length && x.additional_props.some(p => !String(p.name).trim())))
        return onError('Debe definir el nombre de todas las propiedades adicionales en el precio');
    }

    if (!!requiredDocs?.length && requiredDocs.some(x => !x.name))
      return onError('Debe completar el nombre del documento');

    if (!form.details_file)
      return onError('El PDF es obligatorio');

    return true;
  }

  const parseOuput = () => {
    try {
      const data = {
        ...form,
        is_only_for_owner: Number(form.is_only_for_owner),
        is_deductible_based: Number(form.is_deductible_based),
        apply_call: Number(form.apply_call),
        apply_dsr: Number(form.apply_dsr),
        is_aps: Number(form.is_aps),
        is_rcv: Number(form.is_rcv),
        required_docs: JSON.stringify(requiredDocs),
        marketing_owners: JSON.stringify(selectedMarketingChannels.map(x => +x.id)),
        hasFile: true,
      };

      if (form.is_deductible_based) {
        data.deductibles = JSON.stringify(deductibles.map(d => {
          const deductible = clone(d);
          // Establece el orden numérico ascendente de las filas
          deductible.insurability_ages.forEach((row, rowIdx) => {
            deductible.premiums.forEach(p => {
              if (p.row_id === row.id) p.row_order = rowIdx + 1;
            });
          });

          // Establece el orden numérico ascendente de las columnas
          deductible.insured_sums.forEach((col, colIdx) => {
            deductible.premiums.forEach(p => {
              if (p.col_id === col.id) p.col_order = colIdx + 1;
            });
          });

          return {
            id: d?.id || undefined,
            name: d.name,
            premiums: deductible.premiums.map(p => ({
              ...p,
              is_disabled: Number(p.is_disabled),
              insured_sum: parseFloat(p.insured_sum),
              price_monthly: parseFloat(p.price_monthly),
              price_annual: parseFloat(p.price_annual),
              insurability_age: typeof p.insurability_age === 'string'
                ? p.insurability_age
                : p.insurability_age?.join('-'),
            })),
          }}
        ));

      } else {
        data.prices = JSON.stringify(prices.map(x => ({
          ...x,
          id: x?.id || undefined,
          insured_sum: x.insured_sum || 0,
          insurability_age: typeof x.insurability_age === 'string'
            ? x.insurability_age
            : x.insurability_age?.join('-'),
        })));
      }

      data.services = JSON.stringify(services.map(x => ({
        id: x?.id || undefined,
        service_id: x.service_id,
        uses_quantity: x.uses_quantity,
        is_unlimited: x.is_unlimited,
        photo: '',
      })));

      services.forEach((x, index) => {
        data[`service_photo_${index}`] = x.photo;
      });

      return data;
    } catch (error) {
      console.log(error)
    }
  }

  const submit = async () => {
    if (!canFetch || !isValidForm()) return;
    setCanFetch(false);

    try {
      const data = parseOuput();
      isEditing
        ? await ProductsService.admin.update(data)
        : await ProductsService.admin.create(data);
      toast.success(`Producto ${isEditing ? 'actualizado':'creado'} con éxito`);
      setCanFetch(true);
      return true;

    } catch (error) {
      onError(String(error));
      setCanFetch(true);
      return false;
    }
  }

  useEffect(() => {
    if (isEditing && !isNaN(params.id)) {
      getProduct();
    } else {
      form.is_deductible_based
        ? !deductibles.length && addDeductible()
        : !prices.length && addPrice();
    };

    getServices();
    getMarketingChannels();
  }, []);

  return {
    addDeductible,
    addDeductibleAge,
    addDeductiblePremiums,
    addPrice,
    addPriceProp,
    addRequiredDoc,
    addService,
    changeDeductible,
    changeForm,
    changePrice,
    changePriceProp,
    changeRequiredDoc,
    changeService,
    deductibles,
    form,
    getMarketingChannels,
    isEditing,
    isLoading: !canFetch,
    marketingChannels,
    prices,
    removeDeductible,
    removeDeductibleAge,
    removeDeductiblePremiums,
    removePrice,
    removePriceProp,
    removeRequiredDoc,
    removeService,
    requiredDocs,
    selectedMarketingChannels,
    services,
    servicesList,
    submit,
    toggleMarketingChannel,
  };
}

const initialFormValues = () => {
  const INITIAL_PRICE_PROP = {
    name: '',
    insured_sum: '',
  };

  const INITIAL_PRICE = {
    name: '',
    price_monthly: '',
    price_annual: '',
    insurability_age: [0,85],
    insured_sum: '',
    deductible: '',
    additional_props: [],
  };

  const INITIAL_DEDUCTIBLE_AGES = [
    {
      id: uuidv4(),
      minValue: '0',
      maxValue: '30',
    },
    {
      id: uuidv4(),
      minValue: '31',
      maxValue: '65',
    },
    {
      id: uuidv4(),
      minValue: '66',
      maxValue: '75',
    },
    {
      id: uuidv4(),
      minValue: '76',
      maxValue: '85',
    },
  ];

  const INITIAL_DEDUCTIBLE_SUM = {
    id: '',
    insured_sum: '',
  };

  const INITIAL_DEDUCTIBLE_PREMIUM = {
    row_id: '',
    row_order: 0,
    col_id: '',
    col_order: 0,
    is_disabled: false,
    insured_sum: '',
    insurability_age: ['0','0'],
    price_monthly: '',
    price_annual: '',
  };

  const INITIAL_DEDUCTIBLE = {
    name: '',
    insurability_ages: [],
    insured_sums: [],
    premiums: [],
  };

  const INITIAL_SERVICES = {
    photo: '',
    photoUrl: '',
    service_id: '',
    uses_quantity: '',
    is_unlimited: false,
  };

  const INITIAL_REQ_DOC = {
    name: '',
    is_optional: 0,
  };

  const INITIAL_FORM = {
    photo: '',
    photoUrl: '',
    details_file: '',
    name: '',
    description: '',
    fees: '',
    activation_period: '',
    is_only_for_owner: false,
    is_deductible_based: false,
    instructions: '',
    apply_call: false,
    apply_dsr: true,
    is_aps: false,
    is_rcv: false,
    marketing_owners: [],
  };

  return {
    INITIAL_DEDUCTIBLE_AGES,
    INITIAL_DEDUCTIBLE_PREMIUM,
    INITIAL_DEDUCTIBLE_SUM,
    INITIAL_DEDUCTIBLE,
    INITIAL_FORM,
    INITIAL_PRICE_PROP,
    INITIAL_PRICE,
    INITIAL_REQ_DOC,
    INITIAL_SERVICES,
  };
}