/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { Button, Chip, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Pagination, Popover, PopoverContent, PopoverTrigger, Select, SelectItem, Spinner, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow, Tooltip } from '@nextui-org/react';
import { IconFileCertificate, IconEye, IconSearch, IconChevronDown, IconChevronUp, IconDirection } from '@tabler/icons-react';
import toast from 'react-hot-toast';
import moment from 'moment';
import { Constants, autoFormatAmount, formatAmount, fromStorage } from '../../../utils';
import { SubscriptionsService } from '../../../services';
import PopStatus from './popover-status';
import PopoverDocuments from './popover-documents';
import { IconChecklist } from '@tabler/icons-react';

const MODAL_ACTION = {
  NONE: 0,
  VALIDATE: 1,
}

const SORT = {
  UNDEFINED: 0,
  ASC: 1,
  DESC: 2,
}

const LEVEL = {
  MAIN: 1,
  SUB: 2,
}

const statuses = [
  { value: Constants.PURCHASE.STATUS.PENDING.toString(), label: 'Por emitir' },
  { value: Constants.PURCHASE.STATUS.APPROVED.toString(), label: 'Emitida' },
  { value: Constants.PURCHASE.STATUS.REJECTED.toString(), label: 'Rechazada' },
  { value: Constants.PURCHASE.STATUS.ANALIZE.toString(), label: 'Por análisis' },
  { value: Constants.PURCHASE.STATUS.APPROVED_ANALYSIS.toString(), label: 'Análisis aprobado' },
  { value: Constants.PURCHASE.STATUS.NULLED.toString(), label: 'Anulada' },
];

function getStatus(status, statusText) {
  switch (status) {
    case Constants.PURCHASE.STATUS.PENDING:
      return { name: 'Por emitir', color: 'warning' };
    case Constants.PURCHASE.STATUS.APPROVED:
      return { name: 'Emitida', color: 'success' };
    case Constants.PURCHASE.STATUS.REJECTED:
      return { name: 'Rechazada', color: 'danger' };
    case Constants.PURCHASE.STATUS.CANCELLED:
      return { name: 'Cancelada', color: 'danger' };
    case Constants.PURCHASE.STATUS.ANALIZE:
      return { name: 'Por análisis', color: 'primary' };
    case Constants.PURCHASE.STATUS.NULLED:
      return { name: 'Anulada', color: 'default' };
    case Constants.PURCHASE.STATUS.APPROVED_ANALYSIS:
      return { name: 'Análisis aprobado', color: 'primary' };
    default:
      return { name: statusText, color: 'default' };
  }
}

const Subscriptions = () => {
  const [selectedItem, setSelectedItem] = useState();
  const [modalAction, setModalAction] = useState(MODAL_ACTION.NONE);
  const { canResetFilter, data, filters, filterBy, goToPage, isLoading, sortBy, pagination, reload, validateItem, print, updateDocuments, updateStatus } = useFetchTable();

  // const onSelectItem = (item, action) => {
  //   setSelectedItem(item);
  //   setModalAction(action);
  // }

  const closeModal = (reloading = false) => {
    setSelectedItem(null);
    setModalAction(MODAL_ACTION.NONE);
    if (reloading) reload();
  }

  return (
    <>
      {isLoading && (
        <div className="w-screen h-screen fixed inset-0 z-[70] flex justify-center items-center bg-white/30">
          <Spinner />
        </div>
      )}

      <Modal
        size="sm"
        isOpen={modalAction === MODAL_ACTION.VALIDATE}
        onClose={() => closeModal()}
        backdrop="blur"
        scrollBehavior="outside"
      >
        <ModalContent>
          {(onClose) => (
            <ModalValidate
              onClose={onClose}
              payment={selectedItem}
              onValidate={status => {
                validateItem(selectedItem?.id, status);
                closeModal();
              }}
            />
          )}
        </ModalContent>
      </Modal>

      <Filters
        canResetFilter={canResetFilter}
        filterBy={filterBy}
        resetFilter={() => reload()}
      />

      <Table aria-label="Suscripciones"
        topContent={
          <div className="flex flex-row justify-between items-center gap-4 sticky top-0 left-0">
            <h3 className="text-xl font-medium text-primary">Suscripciones</h3>
            <Button variant="bordered" onClick={() => print()}>
              Exportar
            </Button>
          </div>
        }
        topContentPlacement="inside"
      >
        <TableHeader>
          <TableColumn>#</TableColumn>
          <TableColumn>Fecha de creación</TableColumn>
          <TableColumn>Nombre</TableColumn>
          <TableColumn>Cédula</TableColumn>
          <TableColumn>Fecha de nacimiento</TableColumn>
          <TableColumn>Teléfono</TableColumn>
          <TableColumn>Correo</TableColumn>
          <TableColumn>Sexo</TableColumn>
          <TableColumn>Parentesco</TableColumn>
          <TableColumn>Producto</TableColumn>
          <TableColumn>Plan</TableColumn>
          <TableColumn>Suma Asegurada</TableColumn>
          <TableColumn>Frec. de Pago</TableColumn>
          <TableColumn onClick={() => sortBy('paymentDateSort')}>
            <div className="flex flex-row cursor-pointer">
              <span>Fecha de pago</span>
              {(filters.paymentDateSort === SORT.UNDEFINED && <IconDirection size={20} />)}
              {(filters.paymentDateSort === SORT.ASC && <IconChevronUp size={16} />)}
              {(filters.paymentDateSort === SORT.DESC && <IconChevronDown size={16} />)}
            </div>
          </TableColumn>
          <TableColumn>Canales de Comercialización</TableColumn>
          <TableColumn>Código de Intermediario</TableColumn>
          <TableColumn>Apellido y Nombre de Intermediario</TableColumn>
          <TableColumn>Estatus</TableColumn>
          <TableColumn align="end" />
        </TableHeader>
        <TableBody items={data} emptyContent="No hay resultados">
          {(item) => {
            const showProducts = item?.purchase_products?.filter(p => !!item.purchase_product_id ? item.purchase_product_id === p.id : true);
            const status = getStatus(item?.status, item.status_text);
            // Para ocultar el popover mediante el dom
            const popStatId = `pop-stat-${item.id}`;
            const popDocsId = `pop-docs-${item.id}`;

            if (item._level === LEVEL.SUB) return (
              <TableRow key={item._index} className="bg-gray-50">
                <TableCell className="flex justify-end items-center"></TableCell>
                <TableCell className="item-sub">
                  <div className="hierarchy--base" style={{ height: item._isLastIdx ? '50%':'100%' }} />
                  <div className="hierarchy--indicator" />
                  { moment(item?.created_at).format('DD/MM/YYYY') }
                </TableCell>
                <TableCell>{ item?.user?.fullName }</TableCell>
                <TableCell>{ item?.user?.dni }</TableCell>
                <TableCell>{ moment(item?.user?.birthdate).format('DD/MM/YYYY') }</TableCell>
                <TableCell>{ item?.user?.phone }</TableCell>
                <TableCell>{ item?.user?.email }</TableCell>
                <TableCell>{ item?.user?.gender?.name }</TableCell>
                <TableCell>{ item?.user?.relationship?.name }</TableCell>
                <TableCell>{ item?.productNames }</TableCell>
                <TableCell>{ item?.productPlanNames }</TableCell>
                <TableCell>{ item?.productInsuredSums }</TableCell>
                <TableCell>{ item?.productFreqPayment }</TableCell>
                <TableCell>{ item?.payment_date && moment(item?.payment_date).format('DD/MM/YYYY') }</TableCell>
                <TableCell>{ item?.marketingTypeText }</TableCell>
                <TableCell />
                <TableCell />
                <TableCell>
                  <Popover placement="bottom" showArrow backdrop="opaque">
                    <PopoverTrigger>
                      <Chip id={popStatId} color={status.color} variant="flat" className="cursor-pointer">
                        { status.name }
                      </Chip>
                    </PopoverTrigger>
                    <PopoverContent>
                      <PopStatus
                        item={item}
                        onSubmit={(form) => {
                          updateStatus(form);
                          document.getElementById(popStatId)?.click();
                        }}
                      />
                    </PopoverContent>
                  </Popover>
                </TableCell>
                <TableCell />
              </TableRow>
            );

            return (
              <TableRow key={item._index}>
                <TableCell>{ item?.id }</TableCell>
                <TableCell>{ moment(item?.created_at).format('DD/MM/YYYY') }</TableCell>
                <TableCell>{ item?.user?.fullName }</TableCell>
                <TableCell>{ item?.user?.dni }</TableCell>
                <TableCell>{ moment(item?.user?.birthdate).format('DD/MM/YYYY') }</TableCell>
                <TableCell>{ item?.user?.phone }</TableCell>
                <TableCell>{ item?.user?.email }</TableCell>
                <TableCell>{ item?.user?.gender?.name }</TableCell>
                <TableCell>{ item?.user?.relationship?.name }</TableCell>
                <TableCell>{ item?.productNames }</TableCell>
                <TableCell>{ item?.productPlanNames }</TableCell>
                <TableCell>{ item?.productInsuredSums }</TableCell>
                <TableCell>{ item?.productFreqPayment }</TableCell>
                <TableCell>{ (!!item?.productNames && item?.payment_date) && moment(item?.payment_date).format('DD/MM/YYYY') }</TableCell>
                <TableCell>{ item?.marketingTypeText }</TableCell>
                <TableCell>{ item?.marketingCode }</TableCell>
                <TableCell>{ item?.marketingName }</TableCell>
                <TableCell>
                  {!!item?.purchase_product_id && (
                    <Popover placement="bottom" showArrow backdrop="opaque">
                      <PopoverTrigger>
                        <Chip id={popStatId} color={status.color} variant="flat" className="cursor-pointer">
                          { status.name }
                        </Chip>
                      </PopoverTrigger>
                      <PopoverContent>
                        <PopStatus
                          item={item}
                          onSubmit={(form) => {
                            updateStatus(form);
                            document.getElementById(popStatId)?.click();
                          }}
                        />
                      </PopoverContent>
                    </Popover>
                  )}
                </TableCell>
                <TableCell align="right">
                  <div className="relative flex justify-end items-center gap-2">
                    {!!item?.purchase_dsr?.length && (
                      <Popover placement="left" showArrow offset={10}>
                        <PopoverTrigger>
                          <IconChecklist className="cursor-pointer" />
                        </PopoverTrigger>
                        <PopoverContent className="w-80">
                          {(titleProps) => {
                            const users = [];
                            item.purchase_dsr?.forEach(dsr => {
                              if (!users.some(u => u.id === dsr?.user_id)) {
                                users.push({ id: dsr.user_id, name: dsr.user?.fullName});
                              }
                            });

                            return (
                              <div className="px-1 py-2 w-full max-h-96 overflow-y-auto">
                                <p className="text-small font-bold text-foreground" {...titleProps}>
                                  Declaración de Salud Resumida
                                </p>
                                {users.map((user, userIdx) => {
                                  const questions = item.purchase_dsr?.filter(x => x.user_id === user.id);
                                  return (
                                    <div key={userIdx} className="mt-2 pt-2 flex flex-col w-full border-t">
                                      <p className="mb-0 font-semibold">{ user.name }</p>
                                      {questions.map((dsrItem, dsrItemIdx) => (
                                        <div key={dsrItemIdx} className="mb-3">
                                          <p className="mb-0"><i>{ dsrItem?.question?.question }</i></p>
                                          <p className="font-semibold">R: { dsrItem?.answer?.label }</p>
                                        </div>
                                      ))}
                                    </div>
                                  )
                                })}
                              </div>
                            )
                          }}
                        </PopoverContent>
                      </Popover>
                    )}
                    <Popover placement="left" showArrow offset={10}>
                      <PopoverTrigger>
                        <IconEye className="cursor-pointer" />
                      </PopoverTrigger>
                      <PopoverContent className="w-80">
                        {(titleProps) => (
                          <div className="px-1 py-2 w-full">
                            <p className="text-small font-bold text-foreground" {...titleProps}>
                              Detalles del pago
                            </p>
                            {showProducts.map((x, idx) => (
                              <div key={idx} className="mt-2 pt-2 flex flex-col w-full border-t">
                                <p className="mb-0">
                                  <span className="font-semibold">Beneficiario: </span>
                                  <span>{ x?.user?.fullName }</span>
                                </p>
                                <p className="mb-0">
                                  <span className="font-semibold">Fecha de nacimiento: </span>
                                  <span>{ moment(x?.user?.birthdate).format('DD/MM/YYYY') }</span>
                                </p>
                                <p className="mb-0">
                                  <span className="font-semibold">Sexo: </span>
                                  <span>{ x?.user?.gender?.name }</span>
                                </p>
                                {!!x?.user?.relationship?.name && (
                                  <p className="mb-0">
                                    <span className="font-semibold">Parentesco: </span>
                                    <span>{ x?.user?.relationship?.name }</span>
                                  </p>
                                )}
                                <p className="mb-0">
                                  <span className="font-semibold">Producto: </span>
                                  <span>{ x?.product?.name }</span>
                                </p>
                              </div>
                            ))}
                          </div>
                        )}
                      </PopoverContent>
                    </Popover>
                    <Popover placement="left" showArrow backdrop="opaque">
                      <PopoverTrigger>
                        <i id={popDocsId}>
                          <Tooltip content="Documentos">
                            <IconFileCertificate className="cursor-pointer" />
                          </Tooltip>
                        </i>
                      </PopoverTrigger>
                      <PopoverContent>
                        <PopoverDocuments
                          user={item.user}
                          docs={item.purchase_req_docs}
                          products={item.purchase_products}
                          onSubmit={async (form) => {
                            const isSuccess = await updateDocuments(form);
                            if (isSuccess) document.getElementById(popDocsId)?.click();
                          }}
                        />
                      </PopoverContent>
                    </Popover>
                    {/* {payment.status === Constants.PAYMENT.STATUS.PENDING && (
                      <Tooltip content="Validar">
                        <IconChecklist onClick={() => onSelectItem(payment, MODAL_ACTION.VALIDATE)} />
                      </Tooltip>
                    )} */}
                  </div>
                </TableCell>
              </TableRow>
            )
          }}
        </TableBody>
      </Table>

      <div className="flex w-full justify-center mt-4">
        <Pagination
          showControls
          variant="bordered"
          page={pagination.page}
          total={pagination.pages}
          onChange={goToPage}
        />
      </div>
    </>
  );
}

const Filters = ({ canResetFilter, filterBy, resetFilter }) => {
  const initialFilter = {
    since: '',
    until: '',
    search: '',
    searchProduct: '',
    status: '',
  };
  const [form, setForm] = useState(initialFilter);

  const onChange = (value, target) => {
    setForm(s => ({ ...s, [target]: value }));
    filterBy(value, target);
  }

  return (
    <section className="mb-4 flex flex-col lg:flex-row items-end gap-4">
      <Input
        classNames={{
          base: 'w-full sm:max-w-[15rem]',
          inputWrapper: 'border-1 h-10',
        }}
        label="Buscar"
        labelPlacement="outside"
        placeholder="Usuario o C.I."
        startContent={<IconSearch />}
        variant="bordered"
        value={form.search}
        onValueChange={v => onChange(v, 'search')}
      />
      <Input
        type="date"
        classNames={{
          base: 'w-full sm:max-w-[10rem]',
          inputWrapper: 'border-1 h-10',
          input: `pr-0 text-${!!form.since ? '[]':'foreground-400'}`,
        }}
        label="Fecha de pago (desde)"
        labelPlacement="outside"
        placeholder="Seleccionar"
        variant="bordered"
        value={form.since}
        onValueChange={v => onChange(v, 'since')}
      />
      <Input
        type="date"
        classNames={{
          base: 'w-full sm:max-w-[10rem]',
          inputWrapper: 'border-1 h-10',
          input: `pr-0 text-${!!form.until ? '[]':'foreground-400'}`,
        }}
        label="Fecha de pago (hasta)"
        labelPlacement="outside"
        placeholder="Seleccionar"
        variant="bordered"
        value={form.until}
        onValueChange={v => onChange(v, 'until')}
      />
      <Select
        label="Estatus"
        labelPlacement="outside"
        placeholder="Seleccionar"
        variant="bordered"
        className="sd:max-w-xs"
        classNames={{ base: 'w-full sm:max-w-[12rem]', trigger: 'border-1' }}
        disallowEmptySelection={true}
        selectedKeys={!!form.status ? [form.status]:[]}
        onSelectionChange={v => onChange(v.currentKey, 'status')}
      >
        {statuses.map((item) => (
          <SelectItem key={item.value} value={item.value}>
            { item.label }
          </SelectItem>
        ))}
      </Select>
      <Input
        className="sd:max-w-xs"
        classNames={{ base: 'w-full sm:max-w-[10rem]' }}
        label="Buscar"
        labelPlacement="outside"
        placeholder="Producto"
        variant="bordered"
        value={form.searchProduct}
        onValueChange={v => onChange(v, 'searchProduct')}
      />
      {canResetFilter && (
        <Button
          variant="light"
          className="text-primaryDark"
          onClick={() => {
            setForm(initialFilter);
            resetFilter();
          }}
        >
          Limpiar filtros
        </Button>
      )}
    </section>
  )
}

const ModalValidate = ({ payment, onValidate }) => {
  return (
    <>
      <ModalHeader className="flex flex-col gap-1">Validación de Pago</ModalHeader>
      <ModalBody className="gap-0">
        {!!payment.bank && (
          <p><b>Banco emisor: </b>{ payment?.bank?.name }</p>
        )}
        <p><b>Fecha de pago: </b>{ moment(payment.date).format('DD/MM/YY') }</p>
        <p><b>Método de pago: </b>{ payment.payment_method?.name }</p>
        <p><b>Monto pagado: </b>{ autoFormatAmount(payment.amount, payment.payment_method?.currency, payment.conversion) }</p>
        {!!payment.reference && (
          <p><b>N° de referencia o tarjeta: </b>{ payment.reference }</p>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          color="danger"
          className="flex-1"
          onPress={() => onValidate(Constants.PAYMENT.STATUS.REJECTED)}
        >
          Rechazar
        </Button>

        <Button
          color="success"
          className="text-white flex-1"
          onPress={() => onValidate(Constants.PAYMENT.STATUS.APPROVED)}
        >
          Aceptar
        </Button>
      </ModalFooter>
    </>
  )
}

const useFetchTable = () => {
  const initialFilters = {
    page: 1,
    perPage: Constants.PER_PAGE,
    search: '',
    searchProduct: '',
    status: '',
    since: null,
    until: null,
    paymentDateSort: SORT.UNDEFINED,
  };

  const initialPagination = {
    page: 1,
    pages: 1,
    total: 0,
    perPage: Constants.PER_PAGE,
  };

  const [data, setData] = useState([]);
  const [canFetch, setCanFetch] = useState(true);
  const [filters, setFilters] = useState(initialFilters);
  const [pagination, setPagination] = useState(initialPagination);

  const debounceTime = 500;
  const debounce = useRef();

  const fetchData = async () => {
    if (!canFetch) return;
    setCanFetch(false);

    try {
      const response = await SubscriptionsService.admin.findAll(filters);
      const { data, ...rest } = response;
      const _data = [];

      data.forEach((item, mainIdx) => {
        const _lastIdx = item?.purchase_products?.length - 1;
        const ownerProds = item?.purchase_products?.filter(subItem => subItem.user_id === item.user_id);
        const firstOwnerProd = ownerProds?.length > 0 ? ownerProds[0] : null;

        let productInsuredSums = firstOwnerProd?.is_deductible_based
          ? firstOwnerProd?.deductible_premium?.insured_sum
          : firstOwnerProd?.plan?.insured_sum || '';

        _data.push({
          ...item,
          productNames: firstOwnerProd?.product?.name || '',
          productPlanNames: firstOwnerProd?.is_deductible_based
            ? firstOwnerProd?.deductible?.name
            : firstOwnerProd?.plan?.name || '',
          productInsuredSums: !!productInsuredSums ? formatAmount(productInsuredSums || '', '$') : '', 
          productFreqPayment: firstOwnerProd?.payment_frequency?.name || '',
          _level: LEVEL.MAIN,
          _index: mainIdx,
          // Datos del producto si tiene para poder editar el estatus
          purchase_product_id: firstOwnerProd?.id,
          status: firstOwnerProd?.status ?? undefined,
          status_text: firstOwnerProd?.status_text ?? undefined,
          marketingName: !!firstOwnerProd ? item?.user?.referral_user?.name : undefined,
          marketingCode: !!firstOwnerProd ? item?.user?.referral_user?.intermediary_code : undefined,
        });

        item?.purchase_products?.forEach((subItem, subIdx) => {
          const productPlanNames = subItem?.is_deductible_based
            ? subItem?.deductible?.name
            : subItem?.plan?.name;

          const productInsuredSums = subItem?.is_deductible_based
            ? subItem?.deductible_premium?.insured_sum
            : subItem?.plan?.insured_sum;

          if (item.user_id !== subItem.user_id) {
            _data.push({
              ...subItem,
              productNames: subItem?.product?.name || '',
              productPlanNames: productPlanNames || '',
              productInsuredSums: formatAmount(productInsuredSums || '', '$'),
              productFreqPayment: subItem?.payment_frequency?.name || '',
              marketingTypeText: item?.user?.referral_user?.marketing_type_text,
              marketingName: item?.user?.referral_user?.name,
              marketingCode: item?.user?.referral_user?.intermediary_code,
              _level: LEVEL.SUB,
              _index: `${mainIdx}-${subIdx}`,
              _isLastIdx: subIdx === _lastIdx,
              payment_date: item?.payment_date,
              // Datos del producto si tiene para poder editar el estatus
              purchase_product_id: subItem?.id,
            });

          } else {
            if (ownerProds.length > 1 && subItem.product?.id !== firstOwnerProd?.product?.id) {
              _data.push({
                ...subItem,
                productNames: subItem?.product?.name || '',
                productPlanNames: productPlanNames || '',
                productInsuredSums: formatAmount(productInsuredSums || '', '$'),
                productFreqPayment: subItem?.payment_frequency?.name || '',
                marketingTypeText: item?.user?.referral_user?.marketing_type_text,
                marketingName: item?.user?.referral_user?.name,
                marketingCode: item?.user?.referral_user?.intermediary_code,
                _level: LEVEL.SUB,
                _index: `${mainIdx}-${subIdx}`,
                _isLastIdx: subIdx === _lastIdx,
                // Datos del producto si tiene para poder editar el estatus
                purchase_product_id: subItem?.id,
              });
            }
          }
        });
      });

      setData(_data);
      setPagination(rest);
      setCanFetch(true);

    } catch (error) {
      setData([]);
      onError(String(error));
    }
  }

  const validateItem = async (id, status) => {
    if (!canFetch) return;
    setCanFetch(false);

    try {
      await SubscriptionsService.admin.update({ id, status });
      const action = status === Constants.PAYMENT.STATUS.APPROVED ? 'aprobado' : 'rechazado';
      toast.success(`Pago ${action} con éxito`);
      fetchData();

    } catch (error) {
      onError(String(error));
    }
    setCanFetch(true);
  }

  const print = async () => {
    setCanFetch(false);
    try {
      const res = await SubscriptionsService.admin.downloadAll(filters);
      const el = document.createElement('a');
      el.href = fromStorage(res.url);
      el.download = 'Suscripciones';
      el.target = '_blank';
      el.click();
      el.remove();

    } catch (error) {
      onError(String(error));
    }
    setCanFetch(true);
  }

  const updateDocuments = async (form) => {
    if (!canFetch) return;
    setCanFetch(false);

    try {
      await SubscriptionsService.admin.updateDocuments(form);
      toast.success('Documentos actualizados con éxito');
      fetchData();
      return true;

    } catch (error) {
      onError(String(error));
    }
    setCanFetch(true);
  }

  const updateStatus = async (form) => {
    if (!canFetch) return;
    setCanFetch(false);

    try {
      await SubscriptionsService.admin.updateStatus(form);
      toast.success('Estatus actualizado con éxito');
      fetchData();

    } catch (error) {
      onError(String(error));
    }
    setCanFetch(true);
  }

  const getCurrentPagination = () => {
    // Truco para obtener el estado actualizado (pagination es mantenida con el estado actual por el componente Pagination)
    let pag;
    setPagination(s => {
      pag = s;
      return s;
    });
    return pag;
  }

  const canResetFilter = () => {
    const { page, perPage, ...initial } = initialFilters;
    const { page: _, perPage: __, ...current } = filters;
    const initFilter = JSON.stringify(initial);
    const currFilter = JSON.stringify(current);
    return initFilter !== currFilter;
  }

  const onError = (msg) => toast.error(msg);

  const reload = (inSamePage = false) => {
    setCanFetch(true);
    if (!inSamePage) setFilters(initialFilters);
    else fetchData();
  }

  const goToPage = (page) => {
    const pagination = getCurrentPagination();
    if (page >= 1 && page <= pagination.pages && page !== pagination.page) {
      setCanFetch(true);
      setFilters({ ...filters, page });
    }
  }

  const filterBy = (value, target) => {
    if (debounce.current) clearTimeout(debounce.current);
    debounce.current = setTimeout(() => {
      setCanFetch(true);
      setFilters({
        ...filters,
        page: 1,
        [target]: value,
      });
    }, debounceTime);
  }

  const sortBy = (target) => {
    let value = filters[target];
    if (value === SORT.UNDEFINED) value = SORT.ASC
    else if (value === SORT.ASC) value = SORT.DESC
    else value = SORT.UNDEFINED;

    setCanFetch(true);
    setFilters({
      ...filters,
      page: 1,
      [target]: value,
    });
  }

  useEffect(() => {
    fetchData();
  }, [filters]);

  return {
    canResetFilter: canResetFilter(),
    data,
    filterBy,
    filters,
    goToPage,
    isLoading: !canFetch,
    pagination,
    print,
    reload,
    sortBy,
    updateDocuments,
    updateStatus,
    validateItem,
  }
}

export default Subscriptions;
