/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import QrCodeWithLogo from 'qrcode-with-logos';
import { v4 as uuidv4 } from 'uuid';
import { GeneralService, MarketingService, ProductsService } from '../../../../../services';
import { base64ToFile, clone, Constants, fromPhotos, getReferralURL } from '../../../../../utils';

const TYPE = Constants.USER.MARKETING_TYPE;

export const useForm = (type, user) => {
  const initialForm = {
    id:  user?.id ?? '',
    marketing_type: type,
    name: user?.name ?? '',
    dniType: user?.dni ? String(user.dni).substring(0,1) : '',
    dni: user?.dni ? String(user.dni).substring(1) : '',
    phoneCode: user?.phone ? String(user.phone).substring(0,4) : '',
    phone: user?.phone ? String(user.phone).substring(4) : '',
    phoneCodeSecondary: user?.phone_secondary ? String(user.phone_secondary).substring(0,4) : '',
    phone_secondary: user?.phone_secondary ? String(user.phone_secondary).substring(4) : '',
    birthdate: user?.birthdate ?? '',
    email: user?.email ?? '',
    address: user?.address ?? '',
    state_id: user?.state_id ? String(user?.state_id) : null,
    township_id: user?.township_id ? String(user?.township_id) : null,
    parish_id: user?.parish_id ? String(user?.parish_id) : null,
    economic_activity: user?.economic_activity ?? '',
    intermediary_code: user?.intermediary_code ?? '',
    // Comisiones por ventas
    apply_first_fee_commission: user?.apply_first_fee_commission ?? '',
    first_fee_commission_perc: user?.first_fee_commission_perc ?? '',
    apply_all_fee_commission: user?.apply_all_fee_commission ?? '',
    all_fee_commission_perc: user?.all_fee_commission_perc ?? '',
    // Autorizado para
    can_assign_qr: user?.can_assign_qr ?? false,
    can_sell_all_products: user?.can_sell_all_products ?? false,
    // Referidos
    marketing_distributor_id: user?.marketing_distributor_id ? String(user.marketing_distributor_id) : null,
    marketing_seller_id: user?.marketing_seller_id ? String(user.marketing_seller_id) : null,
    marketing_qr: null, // Generated base64 File
    marketing_qr_id: user?.marketing_qr_id || null, // UUID
    marketing_qr_file: user?.marketing_qr_file ? fromPhotos(user.marketing_qr_file) : null, // File url
    marketing_qr_link: user?.marketing_qr_id ? getReferralURL(user?.marketing_qr_id) : '', // QR Link
    send_credentials: true,
  };

  const isEditing = !!user?.id;
  const nav = useNavigate();
  const [isLoading, setisLoading] = useState(false);
  const [form, setForm] = useState(initialForm);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [products, setProducts] = useState([]);
  const [states, setStates] = useState([]);
  const [townships, setTownships] = useState([]);
  const [parishes, setParishes] = useState([]);
  const [phoneCodes, setPhoneCodes] = useState([]);
  const [distributors, setDistributors] = useState([]);
  const [sellers, setSellers] = useState([]);
  const [productsCommissions, setProductsCommissions] = useState([]);
  const [productsToSell, setProductsToSell] = useState([]);

  const getSelects = async () => {
    // Selects independientes
    if (isEditing && user?.state_id) getTownships(user.state_id);
    if (isEditing && user?.township_id) getParishes(user.township_id);

    // Selects dependientes
    const promises = [
      GeneralService.getDocumentTypes(),
      GeneralService.getStates(),
      GeneralService.getPhoneCodes(),
      ProductsService.admin.findAll(),
    ];

    if (type === TYPE.SELLER)
      promises.push(MarketingService.user.findAllForSelect({ marketing_type: TYPE.DISTRIBUTOR }));

    if (type === TYPE.STORE) {
      promises.push(MarketingService.user.findAllForSelect({ marketing_type: TYPE.DISTRIBUTOR }));
      promises.push(MarketingService.user.findAllForSelect({ marketing_type: TYPE.SELLER }));
    }

    Promise.all(promises)
      .then(async (res) => {
        setDocumentTypes(res[0] ?? []);
        setStates(res[1].map(x => ({ value: x.id, label: x.name })) ?? []);
        setPhoneCodes(res[2] ?? []);
        const allProducts = res[3].data.map(x => ({
          id: x.id,
          value: x.id,
          label: x.name,
          marketing_owners: x.marketing_owners,
        })) ?? [];
        setAllProducts(allProducts);

        if (type === TYPE.DISTRIBUTOR || type === TYPE.INTERMEDIARY) {
          if (isEditing) {
            const prods = allProducts.filter(x => {
              const nonExclusive = !x.marketing_owners?.length;
              const isTheirExclusives = x.marketing_owners?.some(o => o.user_id === user?.id);
              return nonExclusive || isTheirExclusives;
            });
            setProducts(prods);

          } else {
            setProducts(allProducts.filter(x => !x.marketing_owners?.length));
          }
        }

        if (type === TYPE.SELLER)
          setDistributors(res[4].map(x => ({ ...x, value: String(x.id), label: x.name })));

        if (type === TYPE.STORE) {
          setDistributors(res[4].map(x => ({ ...x, value: String(x.id), label: x.name })));
          setSellers(res[5].map(x => ({ ...x, value: String(x.id), label: x.name })));
        }
      })
      .catch(console.log);
  }

  const getTownships = async (state_id) => {
    if (!state_id) return;
    GeneralService.getTownships({ state_id })
      .then(res => {
        setTownships(res.map(x => ({ value: x.id, label: x.name })));
      })
      .catch(console.log);
  }

  const getParishes = async (township_id) => {
    if (!township_id) return;
    GeneralService.getParishes({ township_id })
      .then(res => {
        setParishes(res.map(x => ({ value: x.id, label: x.name })));
      })
      .catch(console.log);
  }

  const changeDistributor = (value) => {
    // Establece en el select los productos asignados al distribuidor
    const distributor = distributors.find(x => x.value === value);
    if (!distributor) return;
    const distProductsIds = distributor?.products_commissions?.map(x => x.product_id) || [];
    // Obtiene los productos en base a los productos asignados al distribuidor
    const distProducts = clone(allProducts.filter(x => distProductsIds.includes(x.value)));
    // Obtiene la comisión por producto en base a los productos asignados al distribuidor
    const prods = distProducts.map(x => {
      const distCommissions = distributor?.products_commissions.find(c => c.product_id === x.value);
      return {
        ...x,
        commission_perc: distCommissions?.commission_perc,
        commission_perc_distributor: distCommissions?.commission_perc_distributor,
      };
    });
    setProducts(prods);
  }

  const changeSeller = (value) => {
    // Establece el Distribuidor en base al Vendedor seleccionado
    const seller = sellers.find(x => x.value === value);
    if (!seller) return;

    const distributor = distributors.find(x => +x.value === seller?.marketing_distributor_id);
    if (!distributor) return;

    setForm(s => ({ ...s, marketing_distributor_id: seller?.marketing_distributor_id }));

    const sellProductsIds = seller?.products_commissions.map(x => x.product_id);
    // Obtiene los productos en base a los productos asignados al vendedor
    const sellProducts = clone(allProducts.filter(x => sellProductsIds.includes(x.value)));
    // Obtiene la comisión por producto en base a los productos asignados al vendedor
    const prods = sellProducts?.map(x => {
      const distCommissions = distributor?.products_commissions?.find(c => c.product_id === x.value);
      const sellCommissions = seller?.products_commissions?.find(c => c.product_id === x.value);
      return {
        ...x,
        commission_perc: distCommissions?.commission_perc,
        commission_perc_distributor: distCommissions?.commission_perc_distributor,
        commission_perc_seller: sellCommissions?.commission_perc_seller,
      };
    });
    setProducts(prods);
  }

  const onAddProductsCommissions = (ids) => {
    const items = ids.map(id => {
      const product = products.find(x => x.value === id);
      return {
        product_id: id,
        name: product?.label,
        commission_perc: product?.commission_perc ?? '0',
        commission_perc_distributor: product?.commission_perc_distributor ?? '0',
        commission_perc_seller: product?.commission_perc_seller ?? '0',
        commission_perc_store: product?.commission_perc_store ?? '0',
      };
    });
    setProductsCommissions(s => [...s, ...items].map((x, idx) => ({ ...x, index: idx })));
  }

  const onChange = (value, target) => {
    if (isEditing && target === 'marketing_distributor_id') {
      return toast.error('No se puede asignar un distribuidor distinto');
    }
    if (isEditing && target === 'marketing_seller_id') {
      return toast.error('No se puede asignar un vendedor distinto');
    }

    // Ejecuta el cambio en el formulario
    setForm(s => ({ ...s, [target]: value }));

    // Ejecuta los cambios dependientes del valor/campo seleccionado
    if (target === 'state_id') {
      if (value) getTownships(value);
      onChange(null, 'township_id');
    }
    if (target === 'township_id') {
      if (value) getParishes(value);
      onChange(null, 'parish_id');
    }

    if (target === 'marketing_distributor_id') {
      setProductsCommissions([]);
      setProductsToSell([]);
      changeDistributor(value);
    }

    if (target === 'marketing_seller_id') {
      setProductsCommissions([]);
      setProductsToSell([]);
      changeSeller(value);
    }

    if (target === 'can_sell_all_products') {
      if (value) setProductsToSell([]);
    }
    // Uno anula el otro, no puede estar activos ambos
    if (target === 'apply_first_fee_commission') {
      setForm(s => ({ ...s, apply_all_fee_commission: false }));
    }
    if (target === 'apply_all_fee_commission') {
      setForm(s => ({ ...s, apply_first_fee_commission: false }));
    }
  }

  const onChangeProductsCommissions = (value, target, index) => {
    setProductsCommissions(s => {
      s[index][target] = value;
      return [ ...s ];
    });
  }

  const onAddProductsToSell = (ids) => {
    const items = ids.map(id => ({
      product_id: id,
      name: products.find(x => x.value === id)?.label,
    }));
    setProductsToSell(s => [...s, ...items].map((x, idx) => ({ ...x, index: idx })));
  }

  const onRemoveProductsCommissions = (index) => {
    setProductsCommissions(s => {
      return s
        .filter(x => x.index !== index)
        .map((x, idx) => ({ ...x, index: idx }));
    });
  }

  const onRemoveProductsToSell = (index) => {
    setProductsToSell(s => {
      return s
        .filter(x => x.index !== index)
        .map((x, idx) => ({ ...x, index: idx }));
    });
  }

  const onGenerateQR = async () => {
    const marketing_qr_id = form?.marketing_qr_id ?? uuidv4();
    const url = getReferralURL(marketing_qr_id);

    const qrcode = new QrCodeWithLogo({
      content: url,
      width: 1024,
      // logo: {
      //   src: Logo,
      //   logoRadius: 100,
      //   bgColor: 'red',
      // },
      dotsOptions: {
        color: '#0C33AF',
      },
      cornersOptions: {
        color: '#0C33AF',
      },
    });

    const canvas = await qrcode.getCanvas();
    const imageBase64 = canvas.toDataURL();
    const file = base64ToFile(imageBase64, 'marketing_qr.png');
    setForm(s => ({
      ...s,
      marketing_qr_id,
      marketing_qr: file,
      marketing_qr_file: imageBase64,
      marketing_qr_link: url,
    }));
  }

  const onCopy = () => {
    if (!form.marketing_qr_id) return;
    navigator.clipboard.writeText(getReferralURL(form.marketing_qr_id));
    toast.success('Código copiado al portapapeles');
  }

  const onError = (msg) => {
    toast.error(msg);
    return false;
  }

  const isValidForm = () => {
    // Validaciones generales
    if (!form.name)
      return onError('El nombre es obligatorio');

    if (!form.dniType)
      return onError('El tipo de documento es obligatorio');

    if (!form.dni)
      return onError('El número de documento es obligatorio');

    if (!form.email)
      return onError('El correo es obligatorio');

    // Validaciones por tipo de usuario
    if (type === TYPE.DISTRIBUTOR) {
      if (!form.economic_activity)
        return onError('La actividad esconómica es obligatoria');

      if (!!form.phoneCodeSecondary && form.phoneCodeSecondary.length !== 4)
        return onError('El código de área del teléfono fijo debe tener 4 dígitos');

      if ((!!form.phoneCodeSecondary && !form.phone_secondary) || (!form.phoneCodeSecondary && !!form.phone_secondary))
        return onError('El teléfono fijo no puede estar incompleto');

      if (!productsCommissions.length)
        return onError('Debe seleccionar al menos un producto para vender');

      if (productsCommissions.some(x => !Number(x.commission_perc)))
        return onError('Debe indicar el porcentaje de comisión global');

      if (productsCommissions.some(x => +x.commission_perc < +x.commission_perc_distributor))
        return onError('El porcentaje de comisión del distribuidor no puede ser mayor a la global');
    }

    if (type === TYPE.SELLER) {
      if (!form.economic_activity)
        return onError('La actividad esconómica es obligatoria');

      if (!!form.phoneCodeSecondary && form.phoneCodeSecondary.length !== 4)
        return onError('El código de área del teléfono fijo debe tener 4 dígitos');

      if ((!!form.phoneCodeSecondary && !form.phone_secondary) || (!form.phoneCodeSecondary && !!form.phone_secondary))
        return onError('El teléfono fijo no puede estar incompleto');

      if (!form.phoneCode)
        return onError('El código de área es obligatorio');

      if (!form.phone)
        return onError('El teléfono es obligatorio');

      if (['V','E'].includes(form.dniType) && !form.birthdate)
        return onError('La fecha de nacimiento es obligatoria');

      if (!form.marketing_distributor_id)
        return onError('Debe seleccionar un distribuidor');

      if (!productsCommissions.length)
        return onError('Debe seleccionar al menos un producto para vender');
    }

    if (type === TYPE.STORE) {
      if (!form.economic_activity)
        return onError('La actividad esconómica es obligatoria');

      if (!!form.phoneCodeSecondary && form.phoneCodeSecondary.length !== 4)
        return onError('El código de área del teléfono fijo debe tener 4 dígitos');

      if ((!!form.phoneCodeSecondary && !form.phone_secondary) || (!form.phoneCodeSecondary && !!form.phone_secondary))
        return onError('El teléfono fijo no puede estar incompleto');

      if (!form.marketing_seller_id)
        return onError('Debe seleccionar un vendedor');

      if (!form.marketing_qr_id)
        return onError('Debe generar el código QR');

      if (!productsCommissions.length)
        return onError('Debe seleccionar al menos un producto para vender');
    }

    if (type === TYPE.INTERMEDIARY) {
      if (!!form.phoneCodeSecondary && form.phoneCodeSecondary.length !== 4)
        return onError('El código de área del teléfono fijo debe tener 4 dígitos');

      if ((!!form.phoneCodeSecondary && !form.phone_secondary) || (!form.phoneCodeSecondary && !!form.phone_secondary))
        return onError('El teléfono fijo no puede estar incompleto');

      if (!form.phoneCode)
        return onError('El código de área es obligatorio');

      if (!form.phone)
        return onError('El teléfono es obligatorio');

      if (['V','E'].includes(form.dniType) && !form.birthdate)
        return onError('La fecha de nacimiento es obligatoria');

      if (!form.intermediary_code)
        return onError('El código de intermediario es obligatorio');

      if (!form.marketing_qr_id)
        return onError('Debe generar el código QR');

      if (!productsCommissions.length)
        return onError('Debe seleccionar al menos un producto para vender');

      if (productsCommissions.some(x => !Number(x.commission_perc)))
        return onError('Debe indicar el porcentaje de comisión');
    }

    if (!form.address)
      return onError('La dirección es obligatoria');

    if (!form.state_id)
      return onError('El estado es obligatorio');

    if (!form.township_id)
      return onError('El municipio es obligatorio');

    if (!form.parish_id)
      return onError('La parroquia es obligatoria');

    if (!productsCommissions.length)
      return onError('Debe seleccionar al menos un producto para vender');

    return true;
  }

  const onSubmit = async () => {
    if (!isValidForm()) return;

    setisLoading(true);

    const data = {
      ...form,
      send_credentials: Number(form.send_credentials),
      apply_first_fee_commission: Number(form.apply_first_fee_commission),
      apply_all_fee_commission: Number(form.apply_all_fee_commission),
      can_assign_qr: Number(form.can_assign_qr),
      can_sell_all_products: Number(form.can_sell_all_products),
      productsCommissions: JSON.stringify(productsCommissions.map(p => ({
        product_id: p.product_id,
        commission_perc: p.commission_perc,
        commission_perc_distributor: p.commission_perc_distributor,
        commission_perc_seller: p.commission_perc_seller,
        commission_perc_store: p.commission_perc_store,
      }))),
      productsToSell: JSON.stringify(productsToSell.map(p => p.product_id)),
      marketing_qr_file: null,
      hasFile: true,
    };

    try {
      if (isEditing) {
        await MarketingService.user.update(data);
        toast.success('Intermediario actualizado correctamente');
        nav('/canales-comercializacion/intermediarios');

      } else {
        await MarketingService.user.create(data);
        toast.success('Intermediario creado correctamente');
        resetForm();
      }

    } catch (error) {
      onError(String(error));
    }

    setisLoading(false);
  }

  const resetForm = () => {
    setForm(initialForm);
    setProductsCommissions([]);
    // setProductsToSell([]);
  }

  useEffect(() => {
    getSelects();

    if (user?.products_commissions?.length) {
      setProductsCommissions(user?.products_commissions?.map((x, index) => ({
        index,
        product_id: x.product_id,
        name: x?.product?.name,
        commission_perc: x.commission_perc,
        commission_perc_distributor: x.commission_perc_distributor,
        commission_perc_seller: x.commission_perc_seller,
        commission_perc_store: x.commission_perc_store,
      })));
    }

    // if (user?.assigned_products?.length) {
    //   setProductsToSell(user?.assigned_products?.map((x, index) => ({
    //     index,
    //     product_id: x.product_id,
    //     name: x?.product?.name,
    //   })));
    // }
  }, []);

  useEffect(() => {
    if (isEditing && type === TYPE.SELLER && form?.marketing_distributor_id) {
      changeDistributor(form?.marketing_distributor_id);
    }
    if (isEditing && type === TYPE.STORE && form?.marketing_seller_id) {
      changeSeller(form.marketing_seller_id);
    }
  }, [allProducts]);

  return {
    allProducts,
    distributors,
    documentTypes,
    form,
    isEditing,
    isLoading,
    onAddProductsCommissions,
    onAddProductsToSell,
    onChange,
    onChangeProductsCommissions,
    onCopy,
    onGenerateQR,
    onRemoveProductsCommissions,
    onRemoveProductsToSell,
    onSubmit,
    parishes,
    phoneCodes,
    products,
    productsCommissions,
    productsToSell,
    sellers,
    setProductsToSell,
    states,
    townships,
  }
}
