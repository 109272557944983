import React from 'react';
import { Button, Checkbox, Divider, Input, Select, SelectItem, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow } from '@nextui-org/react';
import { IconX } from '@tabler/icons-react';
import { Constants, getReferralURL, sanitizeToNumbers } from '../../../../../utils';
import { ProductSelector } from '../components';
import { QRImage } from '../../../../../components';
import { useForm } from '.';

const MarketingCreateUserIntermediary = ({ user }) => {
  const { documentTypes, form, isEditing, isLoading, onAddProductsCommissions, onChange, onChangeProductsCommissions, onCopy, onGenerateQR, onRemoveProductsCommissions, onSubmit, parishes, phoneCodes, products, productsCommissions, states, townships } = useForm(Constants.USER.MARKETING_TYPE.INTERMEDIARY, user);

  return (
    <div className="max-w-screen-lg mx-auto flex flex-col bg-white p-4 rounded-lg">
      <h1 className="text-primary text-2xl font-bold text-center mb-8">
        { isEditing ? '' : 'Registro de' } Usuario Intermediario
      </h1>

      <div className="grid grid-cols-3 gap-4 items-start">
        <Input
          label="Nombre del productor"
          labelPlacement="outside"
          placeholder=" "
          classNames={{ inputWrapper: 'border-1' }}
          variant="bordered"
          value={form.name}
          onValueChange={v => onChange(v, 'name')}
        />
        <div className="flex">
          <Select
            label="Cédula de identidad"
            labelPlacement="outside"
            variant="bordered"
            placeholder=" "
            classNames={{
              base: 'w-1/3',
              trigger: 'border-1 border-r-0 rounded-tr-none rounded-br-none',
              label: 'text-nowrap overflow-visible',
            }}
            disallowEmptySelection={true}
            selectedKeys={!!documentTypes.length && !!form.dniType ? [form.dniType] : []}
            onSelectionChange={v => onChange(v.currentKey, 'dniType')}
          >
            {documentTypes.map((item) => (
              <SelectItem key={item.value} value={item.value}>
                { item.label }
              </SelectItem>
            ))}
          </Select>
          <Input
            label=" "
            labelPlacement="outside"
            variant="bordered"
            classNames={{ base: 'w-2/3', inputWrapper: 'border-1 border-l-0 rounded-tl-none rounded-bl-none' }}
            value={form.dni}
            onValueChange={v => onChange(sanitizeToNumbers(v), 'dni')}
          />
        </div>
        <div className="flex">
          <Input
            label="Teléfono Fijo del intermediario (opcional)"
            labelPlacement="outside"
            placeholder=" "
            variant="bordered"
            classNames={{
              base: 'w-1/3',
              inputWrapper: 'border-1 border-r-0 rounded-tr-none rounded-br-none',
              label: 'text-nowrap overflow-visible',
            }}
            maxLength={4}
            value={form.phoneCodeSecondary}
            onValueChange={v => onChange(sanitizeToNumbers(v), 'phoneCodeSecondary')}
          />
          <Input
            label=" "
            labelPlacement="outside"
            variant="bordered"
            classNames={{ base: 'w-2/3', inputWrapper: 'border-1 border-l-0 rounded-tl-none rounded-bl-none' }}
            value={form.phone_secondary}
            onValueChange={v => onChange(sanitizeToNumbers(v), 'phone_secondary')}
          />
        </div>

        <div className="flex">
          <Select
            label="Teléfono Móvil del intermediario (opcional)"
            labelPlacement="outside"
            variant="bordered"
            placeholder=" "
            classNames={{
              base: 'w-1/3',
              trigger: 'border-1 border-r-0 rounded-tr-none rounded-br-none',
              label: 'text-nowrap overflow-visible',
            }}
            disallowEmptySelection={true}
            selectedKeys={!!phoneCodes.length && !!form.phoneCode ? [form.phoneCode] : []}
            onSelectionChange={v => onChange(v.currentKey, 'phoneCode')}
          >
            {phoneCodes.map((item) => (
              <SelectItem key={item.value} value={item.value}>
                { item.label }
              </SelectItem>
            ))}
          </Select>
          <Input
            label=" "
            labelPlacement="outside"
            variant="bordered"
            classNames={{ base: 'w-2/3', inputWrapper: 'border-1 border-l-0 rounded-tl-none rounded-bl-none' }}
            value={form.phone}
            onValueChange={v => onChange(sanitizeToNumbers(v), 'phone')}
          />
        </div>
        <Input
          label="Fecha de nacimiento"
          labelPlacement="outside"
          variant="bordered"
          type="date"
          classNames={{ inputWrapper: 'border-1' }}
          value={form.birthdate}
          onValueChange={v => onChange(v, 'birthdate')}
        />
        <Input
          label="Correo electrónico"
          labelPlacement="outside"
          variant="bordered"
          placeholder=" "
          classNames={{ inputWrapper: 'border-1' }}
          value={form.email}
          onValueChange={v => onChange(v, 'email')}
        />
        <Input
          label="Actividad económica (Ramo) (opcional)"
          labelPlacement="outside"
          variant="bordered"
          placeholder=" "
          classNames={{ inputWrapper: 'border-1' }}
          value={form.economic_activity}
          onValueChange={v => onChange(v, 'economic_activity')}
        />

        <Input
          label="Código de intermediario"
          labelPlacement="outside"
          variant="bordered"
          placeholder=" "
          classNames={{ inputWrapper: 'border-1' }}
          value={form.intermediary_code}
          onValueChange={v => onChange(v, 'intermediary_code')}
        />
        <div />
        <div />

        <div className="col-span-3">
          <Input
            label="Dirección"
            labelPlacement="outside"
            placeholder=" "
            variant="bordered"
            classNames={{ inputWrapper: 'border-1' }}
            value={form.address}
            onValueChange={v => onChange(v, 'address')}
          />
        </div>

        <Select
          label="Estado"
          labelPlacement="outside"
          variant="bordered"
          placeholder=" "
          classNames={{ trigger: 'border-1' }}
          disallowEmptySelection={true}
          selectedKeys={!!states.length && !!form.state_id ? [form.state_id] : []}
          onSelectionChange={v => onChange(v.currentKey, 'state_id')}
        >
          {states.map((item) => (
            <SelectItem key={item.value} value={item.value}>
              { item.label }
            </SelectItem>
          ))}
        </Select>
        <Select
          label="Municipio"
          labelPlacement="outside"
          variant="bordered"
          placeholder=" "
          classNames={{ trigger: 'border-1' }}
          disallowEmptySelection={true}
          selectedKeys={!!townships.length && !!form.township_id ? [form.township_id] : []}
          onSelectionChange={v => onChange(v.currentKey, 'township_id')}
        >
          {townships.map((item) => (
            <SelectItem key={item.value} value={item.value}>
              { item.label }
            </SelectItem>
          ))}
        </Select>
        <Select
          label="Parroquia"
          labelPlacement="outside"
          variant="bordered"
          placeholder=" "
          classNames={{ trigger: 'border-1' }}
          disallowEmptySelection={true}
          selectedKeys={!!parishes.length && !!form.parish_id ? [form.parish_id] : []}
          onSelectionChange={v => onChange(v.currentKey, 'parish_id')}
        >
          {parishes.map((item) => (
            <SelectItem key={item.value} value={item.value}>
              { item.label }
            </SelectItem>
          ))}
        </Select>
      </div>

      {!isEditing && (
        <>
          <Divider className="mt-6 mb-2" />

          <h1 className="text-primary text-lg font-bold mb-2">
            Acceso
          </h1>

          <Checkbox
            isSelected={form.send_credentials}
            onClick={() => onChange(!form.send_credentials, 'send_credentials')}
          >
            Enviar credenciales de acceso por correo
          </Checkbox>
        </>
      )}

      <Divider className="mt-6 mb-2" />

      <h1 className="text-primary text-lg font-bold mb-2">
        Comisiones por ventas
      </h1>

      <Checkbox
        isSelected={form.apply_first_fee_commission}
        onClick={() => onChange(!form.apply_first_fee_commission, 'apply_first_fee_commission')}
      >
        Aplicar % de comisión en la primera cuota
      </Checkbox>

      <Checkbox
        isSelected={form.apply_all_fee_commission}
        onClick={() => onChange(!form.apply_all_fee_commission, 'apply_all_fee_commission')}
      >
        Aplicar % de comisión por todas las cuotas
      </Checkbox>

      <Divider className="mt-6 mb-2" />

      <h1 className="text-primary text-lg font-bold mb-2">
        Autorizado para:
      </h1>

      <Checkbox
        isSelected={form.can_assign_qr}
        onValueChange={() => onChange(!form.can_assign_qr, 'can_assign_qr')}
      >
        Asignar códigos QR para venta de:
      </Checkbox>

      <div className="ml-8 flex flex-col gap-2 mt-2">
        {/* <Checkbox
          isSelected={form.can_sell_all_products}
          onValueChange={() => onChange(true, 'can_sell_all_products')}
          isDisabled={!form.can_assign_qr}
        >
          Todos los productos
        </Checkbox> */}

        <Checkbox
          isSelected={!form.can_sell_all_products}
          onValueChange={() => onChange(false, 'can_sell_all_products')}
          isDisabled={!form.can_assign_qr}
        >
          Productos asignados para la venta
        </Checkbox>

        <ProductSelector
          products={products}
          selectedProducts={productsCommissions.map(x => x.product_id)}
          onSubmit={onAddProductsCommissions}
          isDisabled={!form.can_assign_qr || form.can_sell_all_products}
        />

        {!!productsCommissions.length && (
          <Table
            aria-label="Productos autorizados para vender"
            classNames={{ base: 'mt-4', wrapper: 'p-0 border', th: '!bg-primary !text-white' }}
          >
            <TableHeader>
              <TableColumn>Productos seleccionados</TableColumn>
              <TableColumn>% Comisión Intermediario</TableColumn>
              <TableColumn />
            </TableHeader>
            <TableBody items={productsCommissions}>
              {productsCommissions.map(item => (
                <TableRow key={item.index}>
                  <TableCell>{ item?.name }</TableCell>
                  <TableCell>
                    <Input
                      classNames={{ base: 'w-40', inputWrapper: 'border-1 bg' }}
                      variant="bordered"
                      type="number"
                      max={100}
                      min={0}
                      step={0.01}
                      value={item.commission_perc}
                      onValueChange={v => onChangeProductsCommissions(v, 'commission_perc', item.index)}
                      startContent={(<span>%</span>)}
                    />
                  </TableCell>
                  <TableCell>
                    <div className="flex justify-end">
                      <IconX
                        className="cursor-pointer"
                        onClick={() => onRemoveProductsCommissions(item.index)}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </div>

      <Divider className="mt-6 mb-2" />

      <div>
        <Button className="bg-[#3ACE76] text-white font-semibold" onClick={onGenerateQR}>
          Generar Código QR
        </Button>
      </div>

      <div className="mt-4 flex justify-between items-center gap-4">
        <QRImage image={form.marketing_qr_file} />

        <div className="flex-1 mx-auto">
          {!!form.marketing_qr_id && (
            <div className="border-1 border-gray6 p-1 rounded-lg flex items-center gap-2 w-full">
              <input
                className="outline-none text-sm w-full"
                value={form.marketing_qr_id ? getReferralURL(form.marketing_qr_id) : ''}
                readOnly
              />
              <button
                className="bg-primary text-white text-sm font-semibold px-2 py-1 rounded-md"
                onClick={onCopy}
              >
                <span>Copiar</span>
              </button>
            </div>
          )}
        </div>
      </div>

      <Divider className="mt-6 mb-2" />

      <div className="mt-8 mb-4 flex justify-center">
        <Button
          isLoading={isLoading}
          disabled={isLoading}
          color="primary"
          onPress={onSubmit}
        >
          Guardar
        </Button>
      </div>
    </div>
  )
}

export default MarketingCreateUserIntermediary;
